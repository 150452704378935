<template>
  <ModalsContainer
    :open="open"
    :submitCallback="onValidate"
    @close="$emit('cancel')"
    v-if="editedItem"
    :initial-focus="nomInput"
  >
    <template #title> Ajouter un type de facturation </template>
    <template #subtitle> Entrez le libelle et le code </template>

    <template #left>
      <ModalsIcon :icon="PencilIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4" ref="nomInput">
        <Grid size="3">
          <GridCell span="1">
            <FormInputContainer label="Code de type">
              <InputText
                type="text"
                v-model="editedItem.code"
                :required="true"
                id="code-echeance"
              />
            </FormInputContainer>
          </GridCell>
          <GridCell span="2">
            <FormInputContainer label="Nom du type">
              <InputText
                type="text"
                v-model="editedItem.libelle"
                :required="true"
                id="nom-echeance"
                focus
              />
            </FormInputContainer>
          </GridCell>
        </Grid>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PencilIcon } from '@heroicons/vue/24/outline';
import type { TypeFactu } from '~/types/models/workspace';

const open = ref(true);

type Props = {
  callback: (t: TypeFactu) => void;
  type: TypeFactu;
};

const props = defineProps<Props>();

const nomInput = ref<HTMLElement>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const editedItem = ref<TypeFactu>({
  id: crypto.randomUUID(),
  code: '',
  libelle: '',
});

watch(
  () => props.type,
  value => {
    if (value) editedItem.value = JSON.parse(JSON.stringify(value));
  },
  { immediate: true },
);

const onValidate = async () => {
  pending.value = true;
  if (!editedItem.value) return;
  await props.callback(editedItem.value);
  pending.value = false;
  open.value = false;
  emit('cancel');
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
