export const useDarkerColor = (hexColor: string): string => {
  // Convertir la couleur hexadécimale en RGB
  let r = parseInt(hexColor.substring(1, 3), 16);
  let g = parseInt(hexColor.substring(3, 5), 16);
  let b = parseInt(hexColor.substring(5, 7), 16);

  // Calculer la couleur plus foncée (en soustrayant 20% à chaque composante)
  r = Math.floor(r * 0.5);
  g = Math.floor(g * 0.5);
  b = Math.floor(b * 0.5);

  // Convertir la couleur RGB en hexadécimal
  const darkerHexColor = '#' + r.toString(16) + g.toString(16) + b.toString(16);
  return darkerHexColor;
};
