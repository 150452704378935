<template>
  <div class="flex items-center justify-center">
    <div
      v-tooltip="tooltip"
      class="flex flex-grow-0 cursor-default items-center justify-center gap-3 whitespace-nowrap rounded-2xl px-3 py-1 text-sm font-normal"
      :class="colorClasses"
      :style="badgeStyles"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
type Props = {
  colorClasses?: string;
  colorHex?: string;
  tooltip?: string;
};
const props = defineProps<Props>();

const backgroundColorStyle = computed(() => {
  return `background-color:${props.colorHex};`;
});

const borderColorStyle = computed(() => {
  if (!props.colorHex) return '';
  const borderColor = useDarkerColor(props.colorHex);
  return `border-color:${borderColor};`;
});

const textColorStyle = computed(() => {
  if (!props.colorHex) return '';
  return `color:${useDarkerColor(props.colorHex)};`;
});

const badgeStyles = computed(() => {
  if (!props.colorHex) return '';
  return backgroundColorStyle.value + ' ' + borderColorStyle.value + ' ' + textColorStyle.value;
});
</script>

<style lang="scss" scoped></style>
