<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="z-overlay relative" @close="onClose">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="z-modal fixed inset-0 overflow-y-auto bg-black bg-opacity-60">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="bg-skin-fill-2 relative overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="sm:flex sm:items-start">
                <div
                  class="bg-skin-button-accent mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-opacity-20 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <QuestionMarkCircleIcon class="text-skin-heading h-6 w-6" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-skin-heading text-lg font-medium leading-6">{{
                    title
                  }}</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm" v-html="message"></p>
                  </div>
                </div>
              </div>
              <div class="mt-5 justify-center gap-4 sm:mt-4 sm:flex sm:flex-row-reverse">
                <Button
                  v-for="option in options"
                  :key="option.label"
                  :variant="option.variant || 'primary'"
                  :disabled="oneActionPending"
                  :loading="optionPendings[option.label]"
                  @click.stop="optionCallback(option)"
                >
                  {{ option.label }}
                </Button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline';
import type { BtnVariant } from '../button/button.vue';

export type ChoicesOption = {
  label: string;
  variant?: BtnVariant;
  callback: () => void;
};

const open = ref(true);

type Props = {
  title: string;
  message: string;
  options: ChoicesOption[];
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'close'): void;
}>();

const optionPendings = ref<{ [key: string]: boolean }>({});

watch(
  () => props.options,
  options => {
    options.forEach(option => {
      if (!optionPendings.value[option.label]) {
        optionPendings.value[option.label] = false;
      }
    });
  },
  { immediate: true },
);

const oneActionPending = computed(() => {
  return Object.values(optionPendings.value).some(pending => pending);
});

const optionCallback = async (option: ChoicesOption) => {
  if (optionPendings.value[option.label]) return;
  optionPendings.value[option.label] = true;

  await option.callback();

  optionPendings.value[option.label] = false;
  open.value = false;
};

const onClose = () => {
  open.value = false;
  emit('close');
};
</script>
