import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const loading = useState('loading', () => false);
  return {
    provide: {
      loading: loading,
    },
  };
});
