<template>
  <div class="relative flex flex-col">
    <div
      class="rounded-md border border-transparent transition-all"
      :class="{ '!border-skin-danger bg-skin-danger rounded-bl-none': errorMessage }"
    >
      <slot />
    </div>
    <div
      v-if="errorMessage"
      class="text-skin-inverted bg-skin-danger z-dropdown pointer-events-none absolute left-0 top-full min-w-[10rem] px-2 py-1 text-xs font-medium opacity-0 drop-shadow transition-all"
      :class="{ '!opacity-100': errorMessage }"
    >
      {{ savedErrorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  errorMessage?: string;
}>();

const savedErrorMessage = ref('');

//watch errorMessage and save it to savedErrorMessage
watch(
  () => props.errorMessage,
  errorMessage => {
    if (errorMessage) savedErrorMessage.value = errorMessage;
  },
  { immediate: true },
);
</script>
