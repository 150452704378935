<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="relative z-overlay"
      @close="onClose"
      :initial-focus="initialFocus || contentSlot">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0">
        <div class="bg-gray-500 fixed inset-0 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-modal overflow-y-auto bg-black bg-opacity-60">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative rounded-lg bg-skin-fill-2 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6"
              :class="[sizeClass, { 'h-[90vh]': fullHeight }, panelClasses]">
              <form @submit.prevent="onSubmit" class="flex gap-4" :class="{ 'h-full': fullHeight }">
                <div v-if="$slots.left" class="flex items-start justify-start">
                  <div class="w-full">
                    <slot name="left" />
                  </div>
                </div>
                <Grid :size="gridSize" :gap="4" class="flex-1">
                  <GridCell span="6" :class="{ 'h-full overflow-hidden': fullHeight }">
                    <div class="flex flex-col gap-4">
                      <div v-if="$slots.title || $slots.subtitle">
                        <DialogTitle
                          v-if="$slots.title"
                          as="h3"
                          class="text-xl font-medium leading-6 text-skin-heading">
                          <slot name="title" />
                        </DialogTitle>
                        <span class="text-sm text-skin-muted" v-if="$slots.subtitle">
                          <slot name="subtitle" />
                        </span>
                      </div>
                      <div ref="contentSlot" :class="{ 'flex-1 overflow-hidden': fullHeight }">
                        <slot name="content" />
                      </div>
                      <div :class="[footerClasses]">
                        <slot name="footer" />
                      </div>
                    </div>
                  </GridCell>
                  <GridCell span="1" v-if="$slots.right">
                    <slot name="right" />
                  </GridCell>
                </Grid>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

type Props = {
  open: boolean;
  submitCallback?: (paylod: Event) => Promise<void>;
  initialFocus?: HTMLElement;
  size?: 'full' | 'large' | 'medium' | 'small';
  fullHeight?: boolean;
  panelClasses?: string;
  footerClasses?: string;
};

const props = defineProps<Props>();

const emit = defineEmits(['close']);

const $slots = useSlots();

const contentSlot = ref<HTMLElement>();

const sizeClass = computed(() => {
  switch (props.size) {
    case 'large':
      return 'sm:max-w-6xl';
    case 'medium':
      return 'sm:max-w-3xl';
    case 'small':
      return 'sm:max-w-xl';
    case 'full':
      return 'sm:max-w-[90%]';
    default:
      return 'sm:max-w-xl';
  }
});

const onClose = () => {
  emit('close');
};

const onSubmit = (event: Event) => {
  return props.submitCallback ? props.submitCallback(event) : null;
};

const gridSize = computed(() => {
  if ($slots.left && $slots.right) {
    return 7;
  } else if ($slots.left || $slots.right) {
    return 6;
  } else {
    return 5;
  }
});
</script>
