<template>
  <div>
    <div
      ref="dropZone"
      class="dropzone border-skin-border-dark flex cursor-pointer flex-col items-center justify-center rounded-md border-2 border-dashed px-4 py-10"
      :class="{ 'border-skin-primary': isOverDropZone }"
      @dragover.prevent
      @click="chooseFile"
    >
      <input
        ref="fileInput"
        type="file"
        multiple
        id="file-input"
        @change="handleFileInput"
        class="sr-only"
        :accept="
          props.type
            ? useMimeTypes[props.type].join(',')
            : Object.values(useMimeTypes).flat().join(',')
        "
        :required="required"
      />
      <CloudArrowUpIcon class="text-skin-muted h-10 w-10" />
      <span class="text-skin-muted text-center">
        <span class="font-medium">Cliquer</span>
        <span> ou glisser-déposer un ou plusieurs fichiers à envoyer</span>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CloudArrowUpIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import { MediaType } from '~~/composables/useMimeTypes';

type Props = {
  type?: MediaType;
  required?: boolean;
};

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(['file-selected']);

const files = ref<File[]>([]);

const fileInput = ref<HTMLInputElement>();
const dropZone = ref<HTMLDivElement>();

const handleFileInput = (event: Event) => {
  files.value = [...((event.target as HTMLInputElement).files || [])];

  emit('file-selected', files.value);
};

const handleDrop = (droppedFiles: File[] | null) => {
  if (!droppedFiles) return;
  files.value = droppedFiles;
  emit('file-selected', files.value);
};

const { isOverDropZone } = useDropZone(dropZone, handleDrop);

const chooseFile = () => {
  if (fileInput.value) {
    fileInput.value.click();
  }
};
</script>
