<template>
  <ModalsContainer :open="open" @close="open = false" :submit-callback="onValidate">
    <template #title> Ajouter un ou plusieurs documents </template>
    <template #subtitle> Choisir le type, ajouter un ou plusieurs documents </template>

    <template #left>
      <ModalsIcon :icon="DocumentArrowUpIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <div class="flex w-full gap-2">
          <SelectBox
            :items="types"
            v-model="selectedType"
            capitalize
            :label-props="['label']"
            id-field="name"
            class="grow"
          />
          <Button @click="chooseFile" :icon="PlusIcon" :disabled="!selectedType"></Button>
          <input ref="fileInput" type="file" class="sr-only" @change="handleFileChange" multiple />
        </div>

        <div v-if="selectedFiles.length" class="flex flex-col gap-1">
          <div v-for="(file, index) in selectedFiles" :key="index" class="flex items-center gap-4">
            <DocumentIcon class="h-5 w-5 shrink-0" />
            <div class="flex grow flex-col gap-1">
              <span class="text-sm font-medium">{{
                DocumentType.findByName(file.docType)?.label
              }}</span>
              <span class="text-skin-muted text-xs">{{ file.file.name }}</span>
            </div>
            <XCircleIcon
              class="text-skin-danger h-5 w-5 shrink-0 cursor-pointer text-opacity-70 hover:text-opacity-100"
              @click="removeFile(index)"
            />
          </div>
        </div>
        <div v-else class="text-skin-muted">Aucun fichier sélectionné</div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button variant="muted" @click="open = false" :disabled="pending">Annuler</Button>
        <Button type="submit" :loading="pending" :disabled="pending">Ajouter</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import {
  DocumentArrowUpIcon,
  DocumentIcon,
  PlusIcon,
  XCircleIcon,
} from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import { DocumentType } from '~/documents/types';
import type { FileSelection } from '~~/types/DocumentTypes';

const open = ref(true);

type Props = {
  types: DocumentType[];
  callback: (files: FileSelection[]) => void;
};

const selectedType = ref<DocumentType | null>();

const props = defineProps<Props>();
const pending = ref(false);

const fileInput = ref<HTMLInputElement>();

const selectedFiles = ref<FileSelection[]>([]);

const onValidate = async () => {
  if (selectedFiles.value.length) {
    pending.value = true;
    await props.callback(selectedFiles.value);
    open.value = false;
  }
};

const chooseFile = () => {
  if (fileInput.value) {
    fileInput.value.click();
  }
};

const handleFileChange = (e: Event) => {
  const target = e.target as HTMLInputElement;
  if (target.files && target.files.length && selectedType.value) {
    for (const file of target.files) {
      selectedFiles.value.push({
        docType: selectedType.value?.name,
        file,
      });
    }
    selectedType.value = null;
  }
};

const removeFile = (index: number) => {
  selectedFiles.value.splice(index, 1);
};
</script>
