<template>
  <ModalsContainer :open="open" :submitCallback="onValidate">
    <template #title> Informations arrêté PC </template>
    <template #subtitle>
      Saisissez les informations de l'arrêté de dépot du permis de construire
    </template>

    <template #left>
      <ModalsIcon :icon="PencilIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <FormInputContainer label="Permis accepté ?">
          <InputRadios
            id="accepte"
            v-model="accepte"
            :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]"
            required />
        </FormInputContainer>
        <FormInputContainer label="Date de l'arrêté">
          <InputText type="date" v-model="theDate" id="date-arrete" required />
        </FormInputContainer>
        <FormInputContainer label="Attendre la fin du délai de recours des tiers ?">
          <InputRadios
            id="attenteDelai"
            v-model="attenteDelai"
            :items="[
              { label: 'Oui', value: true },
              { label: 'Non', value: false },
            ]"
            :required="accepte === true" />
        </FormInputContainer>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PencilIcon } from '@heroicons/vue/24/outline';

const open = ref(true);

type Props = {
  callback: (date: string, accepte: boolean, attente: boolean) => void;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const theDate = ref('');
const accepte = ref(null);
const attenteDelai = ref(null);

const attenteDelaiRequired = computed(() => accepte.value === true);
const attenteDelaiIsOk = computed(
  () => !attenteDelaiRequired.value || (attenteDelaiRequired.value && attenteDelai.value !== null),
);

const onValidate = async () => {
  if (!theDate.value || accepte.value === null || !attenteDelaiIsOk.value) return;
  pending.value = true;
  await props.callback(theDate.value, accepte.value, attenteDelai.value || false);
  pending.value = false;
  open.value = false;
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
