<template>
  <ModalsContainer :open="open" :submitCallback="onValidate">
    <template #title> {{ title }} </template>
    <template #subtitle> {{ subtitle }} </template>

    <template #left>
      <ModalsIcon :icon="CurrencyEuroIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <div v-if="informationsComplementaires">
          <div v-for="(item, index) in informationsComplementaires" :key="index">
            <div class="flex items-center gap-4 text-sm">
              <span class="text-skin-muted">{{ item.label }} : </span>
              <span class="font-medium">{{ item.value }}</span>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-4">
          <div class="text-skin-heading text-base font-medium">Règlements :</div>
          <div>
            <PlusCircleIcon
              class="text-skin-heading w-6 cursor-pointer"
              @click="editedReglements.push({ date: '', montant: 0 })"
            />
          </div>
        </div>
        <div class="flex items-center gap-4" v-for="(regl, index) in editedReglements" :key="index">
          <FormInputContainer :label="index === 0 ? 'Date' : ''" class="w-1/2">
            <InputText type="date" v-model="regl.date" required small />
          </FormInputContainer>

          <FormInputContainer :label="index === 0 ? 'Montant' : ''" class="w-1/2">
            <div class="flex items-center gap-2">
              <CurrencyInput v-model="regl.montant" :options="{ currency: 'EUR' }" required small />
              <MinusCircleIcon
                class="text-skin-error w-6 cursor-pointer"
                @click="editedReglements.splice(index, 1)"
              />
            </div>
          </FormInputContainer>
        </div>
        <div v-if="!editedReglements.length" class="border-skin-border rounded-xl border p-4">
          <div class="text-skin-muted text-sm">Aucun règlement.</div>
          <p class="text-skin-heading text-sm">Utilisez le bouton + pour ajouter un reglement</p>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { CurrencyEuroIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/vue/24/outline';
import type { ReglementFactureClient } from '~/types/models/factureClient';

const open = ref(true);

type Props = {
  title: string;
  subtitle: string;
  reglements: ReglementFactureClient[];
  informationsComplementaires?: { label: string; value: string }[];
  callback: (values: ReglementFactureClient[]) => void;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const editedReglements = ref(
  JSON.parse(JSON.stringify(props.reglements || [{ date: '', montant: 0 }])),
);

const onValidate = async () => {
  pending.value = true;
  await props.callback(editedReglements.value);
  pending.value = false;
  open.value = false;
  emit('cancel');
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
