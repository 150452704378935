import { abilitiesPlugin } from '@casl/vue';
import { createMongoAbility } from '@casl/ability';

export default defineNuxtPlugin((nuxtApp) => {
  const ability = createMongoAbility([]);
  nuxtApp.vueApp.use(abilitiesPlugin, ability);

  return {
    provide: {
      ability: ability,
    },
  };
});
