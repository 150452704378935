import { h, createApp, type Component } from 'vue';

import type { ArticleLibre, Precommande } from '~~/types/models/precommande';
import type { ArticleLibreAvenant, AvenantMetas } from '~~/types/models/avenant';

import {
  ModalsAddCommande,
  ModalsAddConditionReglement,
  ModalsAddDocument,
  ModalsAddDocumentWithFolder,
  ModalsAddEcheance,
  ModalsAddLigneCommande,
  ModalsAddMultiTypesDocuments,
  ModalsAddTypeFactu,
  ModalsAlert,
  ModalsArretePc,
  ModalsArticleLibre,
  ModalsArticleLibreTravaux,
  ModalsChoices,
  ModalsCurrencyInput,
  ModalsDateInput,
  ModalsDateMontantInput,
  ModalsDateSignatureContrat,
  ModalsDepotPc,
  ModalsLightBox,
  ModalsLoading,
  ModalsMergeCommandes,
  ModalsMergePrecommandes,
  ModalsMetasAvenant,
  ModalsMoveLignePrecommande,
  ModalsNewModeleVersion,
  ModalsNumberInput,
  ModalsReglementFacture,
  ModalsSaveConfig,
  ModalsSelect,
  ModalsTextareaInput,
} from '#components';
import { vTooltip } from 'floating-vue';
import type { ReglementFactureClient } from '~/types/models/factureClient';
import type { ChoicesOption } from '~/components/modals/choices.vue';
import type { FileSelection } from '~/types/DocumentTypes';
import type { CommandeLigne } from '~/types/models/commandeLigne';
import type { Commande } from '~/types/models/commande';
import type { PrecommandeTypes } from '~/types/Enums';
import type { Echeance } from '~/types/models/echeance';
import type { ConditionDeReglement, TypeFactu } from '~/types/models/workspace';
import AlreadyImportedFiles from '~/components/modals/alreadyImportedFiles.vue';
import type { DocumentType } from '~/documents/types';

// import SearchAndSelect from '~~/components/searchAndSelect.vue';

export type Modals = {
  alert: (title: String, message: String, callback?: Function) => void;
  choices: (title: String, message: String, options: ChoicesOption[]) => void;
  loading: (title: string, message: string | Ref<string>, pending: Ref<any>) => void;
  saveConfig: (
    callback: (config: { name: string; isDefault: boolean }) => void,
    currentConfig: any,
  ) => void;
  lightBox: (url: string) => void;
  addModeleVersion: (modele: any, callback: Function, uniqueCallback: Function) => void;
  addDocument: (
    types: DocumentType[],
    callback: (files: File[], type: string) => void,
    preType?: DocumentType,
  ) => void;
  addDocumentWithFolder: (
    folders: string[],
    callback: (files: File[], folder: string) => void,
  ) => void;
  addMultiTypesDocuments: (
    types: DocumentType[],
    callback: (fils: FileSelection[]) => void,
  ) => void;
  dateInput: (
    title: string,
    subtitle: string,
    currentDate: string,
    callback: (date: string) => void,
    cancelCallback?: () => void,
  ) => void;

  dateMontantInput: (
    title: string,
    subtitle: string,
    currentDate: string,
    currentMontant: number,
    callback: (date: string, montant: number) => void,
    cancelCallback?: () => void,
  ) => void;

  numberInput: (
    title: string,
    subtitle: string,
    currentValue: number,
    callback: (value: number) => void,
    cancelCallback?: () => void,
  ) => void;

  articleLibre: (callback: (article: ArticleLibreAvenant) => void) => void;
  articleLibreTravaux: (callback: (article: ArticleLibre) => void) => void;
  depotPc: (callback: (date: string, delai: number) => void, cancelCallback?: () => void) => void;
  arretePc: (
    callback: (date: string, accepte: boolean, attente: boolean) => void,
    cancelCallback?: () => void,
  ) => void;
  moveLignePrecommande: (
    precommandes: Precommande[],
    currentPrecommande?: Precommande,
    callback?: (precommande: Precommande) => void,
  ) => void;
  addLigneCommande: (callback: (ligne: CommandeLigne) => void, sousLots?: any[]) => void;
  addCommande: (
    callback: (cmd: Commande) => void,
    sousLots?: any[],
    type?: PrecommandeTypes,
  ) => void;
  mergeCommande: (callback: (cmd: Commande) => void, commandes: Commande[]) => void;
  mergePrecommande: (callback: (precmd: Precommande) => void, precommandes: Precommande[]) => void;
  addEcheance: (
    callback: (e: Echeance) => void,
    e: Echeance,
    typesFacturation: TypeFactu[],
  ) => void;
  dateSignatureContrat: (
    currentDate: string,
    currentAcompte: number,
    callback: (date: string, acompte: number) => void,
  ) => void;
  select: (
    title: string,
    subtitle: string,
    currentItem: any,
    labelKeys: string[],
    items: any[],
    callback: (item: any) => void,
    cancelCallback?: () => void,
  ) => void;
  currencyInput: (
    title: string,
    subtitle: string,
    currentValue: number,
    callback: (value: number) => void,
    informationsComplementaires?: { label: string; value: string }[],
    cancelCallback?: () => void,
  ) => void;

  textareaInput: (
    title: string,
    subtitle: string,
    currentValue: string,
    callback: (value: string) => void,
    cancelCallback?: () => void,
  ) => void;

  reglementFacture: (
    title: string,
    subtitle: string,
    reglements: ReglementFactureClient[],
    callback: (values: ReglementFactureClient[]) => void,
    informationsComplementaires?: { label: string; value: string }[],
    cancelCallback?: () => void,
  ) => void;

  avenantMetas: (isPhaseTravaux: boolean, callback: (infos: AvenantMetas) => void) => void;

  addTypeFactu: (type: TypeFactu | null, callback: (type: TypeFactu) => void) => void;

  addConditionReglement: (
    callback: (condition: ConditionDeReglement) => void,
    condition?: ConditionDeReglement,
  ) => void;

  alertAlreadyImportedFiles: (files: File[], callback: (files: File[]) => void) => void;
};

interface ComponentProps {
  [key: string]: any;
}

export const useModals = (): Modals => {
  const createAndMountComponent = (component: Component, props: ComponentProps = {}) => {
    const componentContainer = document.createElement('div');
    const appInstance = createApp({ render: () => h(component, props) });
    appInstance.directive('tooltip', vTooltip);
    appInstance.mount(componentContainer);
    document.body.appendChild(componentContainer);
    return {
      unmount: () => {
        appInstance.unmount();
        document.body.removeChild(componentContainer);
      },
    };
  };
  return {
    alert: (title: String, message: String, callback?: Function) => {
      createAndMountComponent(ModalsAlert, { title: title, message: message, callback: callback });
    },
    choices: (title: String, message: String, options: ChoicesOption[]) => {
      createAndMountComponent(ModalsChoices, { title: title, message: message, options: options });
    },
    loading: (title: string, message: string | Ref<string>, pending: Ref<any>) => {
      createAndMountComponent(ModalsLoading, { title: title, message: message, pending: pending });
    },

    saveConfig: (
      callback: (config: { name: string; isDefault: boolean }) => void,
      currentConfig: any,
    ) => {
      createAndMountComponent(ModalsSaveConfig, { callback, currentConfig });
    },

    lightBox: (url: string) => {
      createAndMountComponent(ModalsLightBox, { url });
    },

    addModeleVersion: (modele: any, callback: Function, uniqueCallback: Function) => {
      createAndMountComponent(ModalsNewModeleVersion, { modele, callback, uniqueCallback });
    },

    addDocument: (
      types: DocumentType[],
      callback: (files: File[], type: string, validite?: string) => void,
      preType?: DocumentType,
    ) => {
      createAndMountComponent(ModalsAddDocument, { types, callback, preType, opened: true });
    },

    addDocumentWithFolder: (
      folders: string[],
      callback: (files: File[], folder: string) => void,
    ) => {
      const app = createAndMountComponent(ModalsAddDocumentWithFolder, { folders, callback });
    },

    addMultiTypesDocuments: (types: DocumentType[], callback: (fils: FileSelection[]) => void) => {
      createAndMountComponent(ModalsAddMultiTypesDocuments, { types, callback });
    },

    select: (
      title: string,
      subtitle: string,
      currentItem: any,
      labelKeys: string[],
      items: any[],
      callback: (item: any) => void,
      cancelCallback?: () => void,
    ) => {
      createAndMountComponent(ModalsSelect, {
        title,
        subtitle,
        currentItem,
        labelKeys,
        items,
        callback,
        onCancel: cancelCallback,
      });
    },

    dateInput: (
      title: string,
      subtitle: string,
      currentDate: string,
      callback: (date: string) => void,
      cancelCallback?: () => void,
    ) => {
      createAndMountComponent(ModalsDateInput, {
        title,
        subtitle,
        currentDate,
        callback,
        onCancel: cancelCallback,
      });
    },

    dateMontantInput: (
      title: string,
      subtitle: string,
      currentDate: string,
      currentMontant: number,
      callback: (date: string, montant: number) => void,
      cancelCallback?: () => void,
    ) => {
      createAndMountComponent(ModalsDateMontantInput, {
        title,
        subtitle,
        currentDate,
        currentMontant,
        callback,
        onCancel: cancelCallback,
      });
    },

    numberInput: (
      title: string,
      subtitle: string,
      currentValue: number,
      callback: (value: number) => void,
      cancelCallback?: () => void,
    ) => {
      createAndMountComponent(ModalsNumberInput, {
        title,
        subtitle,
        currentValue,
        callback,
        onCancel: cancelCallback,
      });
    },

    currencyInput: (
      title: string,
      subtitle: string,
      currentValue: number,
      callback: (value: number) => void,
      informationsComplementaires?: { label: string; value: string }[],
      cancelCallback?: () => void,
    ) => {
      createAndMountComponent(ModalsCurrencyInput, {
        title,
        subtitle,
        currentValue,
        informationsComplementaires,
        callback,
        onCancel: cancelCallback,
      });
    },

    textareaInput: (
      title: string,
      subtitle: string,
      currentValue: string,
      callback: (value: string) => void,
      cancelCallback?: () => void,
    ) => {
      createAndMountComponent(ModalsTextareaInput, {
        title,
        subtitle,
        currentValue,
        callback,
        onCancel: cancelCallback,
      });
    },

    reglementFacture: (
      title: string,
      subtitle: string,
      reglements: ReglementFactureClient[],
      callback: (values: ReglementFactureClient[]) => void,
      informationsComplementaires?: { label: string; value: string }[],
      cancelCallback?: () => void,
    ) => {
      createAndMountComponent(ModalsReglementFacture, {
        title,
        subtitle,
        reglements,
        informationsComplementaires,
        callback,
        onCancel: cancelCallback,
      });
    },

    dateSignatureContrat: (
      currentDate: string,
      currentAcompte: number,
      callback: (date: string, acompte: number) => void,
    ) => {
      createAndMountComponent(ModalsDateSignatureContrat, {
        currentDate,
        currentAcompte,
        callback,
      });
    },

    articleLibre: (
      callback: (article: ArticleLibreAvenant) => void,
      currentArticle?: ArticleLibreAvenant,
    ) => {
      createAndMountComponent(ModalsArticleLibre, { callback, currentArticle });
    },

    articleLibreTravaux: (callback: (article: ArticleLibre) => void) => {
      createAndMountComponent(ModalsArticleLibreTravaux, { callback });
    },

    depotPc: (callback: (date: string, delai: number) => void, cancelCallback?: () => void) => {
      createAndMountComponent(ModalsDepotPc, { callback, onCancel: cancelCallback });
    },

    arretePc: (
      callback: (date: string, accepte: boolean, attente: boolean) => void,
      cancelCallback?: () => void,
    ) => {
      createAndMountComponent(ModalsArretePc, { callback, onCancel: cancelCallback });
    },

    moveLignePrecommande: (
      precommandes: Precommande[],
      currentPrecommande?: Precommande,
      callback?: (precommande: Precommande) => void,
    ) => {
      createAndMountComponent(ModalsMoveLignePrecommande, {
        currentPrecommande,
        precommandes,
        callback,
      });
    },

    addLigneCommande: (callback: (ligne: CommandeLigne) => void, sousLots?: any[]) => {
      createAndMountComponent(ModalsAddLigneCommande, { sousLots, callback });
    },

    addCommande: (callback: (cmd: Commande) => void, sousLots?: any[], type?: PrecommandeTypes) => {
      createAndMountComponent(ModalsAddCommande, { sousLots, callback, type });
    },

    mergeCommande: (callback: (cmd: Commande) => void, commandes: Commande[]) => {
      createAndMountComponent(ModalsMergeCommandes, { commandes, callback });
    },

    mergePrecommande: (callback: (precmd: Precommande) => void, precommandes: Precommande[]) => {
      createAndMountComponent(ModalsMergePrecommandes, { precommandes, callback });
    },

    addEcheance: (callback: (e: Echeance) => void, e: Echeance, typesFacturation: TypeFactu[]) => {
      createAndMountComponent(ModalsAddEcheance, { callback, echeance: e });
    },

    avenantMetas: (isPhaseTravaux: boolean, callback: (infos: AvenantMetas) => void) => {
      createAndMountComponent(ModalsMetasAvenant, { isPhaseTravaux, callback });
    },

    addTypeFactu: (type: TypeFactu | null, callback: (type: TypeFactu) => void) => {
      createAndMountComponent(ModalsAddTypeFactu, { type, callback });
    },

    addConditionReglement: (
      callback: (condition: ConditionDeReglement) => void,
      condition?: ConditionDeReglement,
    ) => {
      createAndMountComponent(ModalsAddConditionReglement, { condition, callback });
    },

    alertAlreadyImportedFiles: (files: File[], callback: (files: File[]) => void) => {
      createAndMountComponent(AlreadyImportedFiles, { files, callback });
    },
  };
};
