export const usePhoneFormat = (phoneNumber: string) => {
  if (!phoneNumber) return '';
  const sanitized = phoneNumber.replace('+', '00').replace(/\D/g, '');

  if (sanitized.startsWith('0033')) {
    const prefix = '+33 ';
    const first = sanitized.slice(4, 5);
    const rest = sanitized.slice(5);
    return prefix + first + ' ' + rest.replace(/(\d{2})/g, '$1 ').trim();
  }
  return sanitized.replace(/(\d{2})/g, '$1 ').trim();
};
