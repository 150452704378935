<template>
  <ModalsContainer :open="open" :submitCallback="onValidate">
    <template #title> Date de signature du contrat </template>
    <template #subtitle> Saisir la date de signature du contrat </template>

    <template #left>
      <ModalsIcon :icon="PencilIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <InputText type="date" v-model="theDate" id="date-pres" required />
        <InputToggle label="Acompte à la signature ?" v-model="isAcompte" />
        <FormInputContainer label="Montant (€)" v-if="isAcompte">
          <CurrencyInput :options="{ currency: 'EUR' }" v-model="acompte" id="acompte" />
        </FormInputContainer>
        <Grid size="1" v-if="!isEditing">
          <GridCell>
            <FormInputContainer label="Délai de réalisation des conditions suspensives (mois)">
              <InputText type="number" v-model="dureeConditions" id="dureeConditions" />
            </FormInputContainer>
          </GridCell>
          <GridCell>
            <FormInputContainer label="Délai de réalisation des travaux (mois)">
              <InputText type="number" v-model="dureeTravaux" id="dureeTravaux" />
            </FormInputContainer>
          </GridCell>
        </Grid>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PencilIcon } from '@heroicons/vue/24/outline';

const open = ref(true);

type Props = {
  currentDate: string;
  currentAcompte: number;
  callback: (
    date: string,
    acompte: number,
    dureeConditions?: number,
    dureeTravaux?: number,
  ) => void;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const isEditing = ref(!!props.currentDate);

const pending = ref(false);

const isAcompte = ref(props.currentAcompte > 0);

const theDate = ref(useDateFormat(props.currentDate, 'yyyy-MM-dd'));
const acompte = ref(props.currentAcompte);
const dureeConditions = ref(2);
const dureeTravaux = ref(8);

const onValidate = async () => {
  if (!theDate.value) return;
  pending.value = true;
  await props.callback(theDate.value, acompte.value, dureeConditions.value, dureeTravaux.value);
  pending.value = false;
  open.value = false;
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
