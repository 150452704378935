import { defineNuxtPlugin } from '#app';
import lodash from 'lodash';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.$pinia.use(() => ({ _: lodash }));
  return {
    provide: {
      _: lodash,
    },
  };
});
