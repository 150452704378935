<template>
  <div class="grid-cell" :class="className">
    <slot />
  </div>
</template>

<script setup lang="ts">
export type SpanSizes =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

type Props = {
  span?: SpanSizes;
  mdSpan?: SpanSizes;
  lgSpan?: SpanSizes;
  xlSpan?: SpanSizes;
  rowSpan?: SpanSizes;
  mdRowSpan?: SpanSizes;
  lgRowSpan?: SpanSizes;
  xlRowSpan?: SpanSizes;
};

const props = withDefaults(defineProps<Props>(), {
  span: '1',
});

const spanClass = computed(() => {
  switch (props.span) {
    case '1':
    case 1:
      return 'sm:col-span-1';
    case '2':
    case 2:
      return 'sm:col-span-2';
    case '3':
    case 3:
      return 'sm:col-span-3';
    case '4':
    case 4:
      return 'sm:col-span-4';
    case '5':
    case 5:
      return 'sm:col-span-5';
    case '6':
    case 6:
      return 'sm:col-span-6';
    case '7':
    case 7:
      return 'sm:col-span-7';
    case '8':
    case 8:
      return 'sm:col-span-8';
    case '9':
    case 9:
      return 'sm:col-span-9';
    case '10':
    case 10:
      return 'sm:col-span-10';
    case '11':
    case 11:
      return 'sm:col-span-11';
    case '12':
    case 12:
      return 'sm:col-span-12';
    default:
      return 'sm:col-span-1';
  }
});

const mdSpanClass = computed(() => {
  switch (props.mdSpan) {
    case '1':
    case 1:
      return 'md:col-span-1';
    case '2':
    case 2:
      return 'md:col-span-2';
    case '3':
    case 3:
      return 'md:col-span-3';
    case '4':
    case 4:
      return 'md:col-span-4';
    case '5':
    case 5:
      return 'md:col-span-5';
    case '6':
    case 6:
      return 'md:col-span-6';
    case '7':
    case 7:
      return 'md:col-span-7';
    case '8':
    case 8:
      return 'md:col-span-8';
    case '9':
    case 9:
      return 'md:col-span-9';
    case '10':
    case 10:
      return 'md:col-span-10';
    case '11':
    case 11:
      return 'md:col-span-11';
    case '12':
    case 12:
      return 'md:col-span-12';
    default:
      return '';
  }
});

const lgSpanClasss = computed(() => {
  switch (props.lgSpan) {
    case '1':
    case 1:
      return 'lg:col-span-1';
    case '2':
    case 2:
      return 'lg:col-span-2';
    case '3':
    case 3:
      return 'lg:col-span-3';
    case '4':
    case 4:
      return 'lg:col-span-4';
    case '5':
    case 5:
      return 'lg:col-span-5';
    case '6':
    case 6:
      return 'lg:col-span-6';
    case '7':
    case 7:
      return 'lg:col-span-7';
    case '8':
    case 8:
      return 'lg:col-span-8';
    case '9':
    case 9:
      return 'lg:col-span-9';
    case '10':
    case 10:
      return 'lg:col-span-10';
    case '11':
    case 11:
      return 'lg:col-span-11';
    case '12':
    case 12:
      return 'lg:col-span-12';
    default:
      return '';
  }
});

const xlSpanClass = computed(() => {
  switch (props.xlSpan) {
    case '1':
    case 1:
      return 'xl:col-span-1';
    case '2':
    case 2:
      return 'xl:col-span-2';
    case '3':
    case 3:
      return 'xl:col-span-3';
    case '4':
    case 4:
      return 'xl:col-span-4';
    case '5':
    case 5:
      return 'xl:col-span-5';
    case '6':
    case 6:
      return 'xl:col-span-6';
    case '7':
    case 7:
      return 'xl:col-span-7';
    case '8':
    case 8:
      return 'xl:col-span-8';
    case '9':
    case 9:
      return 'xl:col-span-9';
    case '10':
    case 10:
      return 'xl:col-span-10';
    case '11':
    case 11:
      return 'xl:col-span-11';
    case '12':
    case 12:
      return 'xl:col-span-12';
    default:
      return '';
  }
});

const rowSpanClass = computed(() => {
  switch (props.rowSpan) {
    case '1':
    case 1:
      return 'row-span-1';
    case '2':
    case 2:
      return 'row-span-2';
    case '3':
    case 3:
      return 'row-span-3';
    case '4':
    case 4:
      return 'row-span-4';
    case '5':
    case 5:
      return 'row-span-5';
    case '6':
    case 6:
      return 'row-span-6';
    case '7':
    case 7:
      return 'row-span-7';
    case '8':
    case 8:
      return 'row-span-8';
    case '9':
    case 9:
      return 'row-span-9';
    case '10':
    case 10:
      return 'row-span-10';
    case '11':
    case 11:
      return 'row-span-11';
    case '12':
    case 12:
      return 'row-span-12';
    default:
      return '';
  }
});

const mdRowSpanClass = computed(() => {
  switch (props.mdRowSpan) {
    case '1':
    case 1:
      return 'md:row-span-1';
    case '2':
    case 2:
      return 'md:row-span-2';
    case '3':
    case 3:
      return 'md:row-span-3';
    case '4':
    case 4:
      return 'md:row-span-4';
    case '5':
    case 5:
      return 'md:row-span-5';
    case '6':
    case 6:
      return 'md:row-span-6';
    case '7':
    case 7:
      return 'md:row-span-7';
    case '8':
    case 8:
      return 'md:row-span-8';
    case '9':
    case 9:
      return 'md:row-span-9';
    case '10':
    case 10:
      return 'md:row-span-10';
    case '11':
    case 11:
      return 'md:row-span-11';
    case '12':
    case 12:
      return 'md:row-span-12';
    default:
      return '';
  }
});

const lgRowSpanClass = computed(() => {
  switch (props.lgRowSpan) {
    case '1':
    case 1:
      return 'lg:row-span-1';
    case '2':
    case 2:
      return 'lg:row-span-2';
    case '3':
    case 3:
      return 'lg:row-span-3';
    case '4':
    case 4:
      return 'lg:row-span-4';
    case '5':
    case 5:
      return 'lg:row-span-5';
    case '6':
    case 6:
      return 'lg:row-span-6';
    case '7':
    case 7:
      return 'lg:row-span-7';
    case '8':
    case 8:
      return 'lg:row-span-8';
    case '9':
    case 9:
      return 'lg:row-span-9';
    case '10':
    case 10:
      return 'lg:row-span-10';
    case '11':
    case 11:
      return 'lg:row-span-11';
    case '12':
    case 12:
      return 'lg:row-span-12';
    default:
      return '';
  }
});

const xlRowSpanClass = computed(() => {
  switch (props.xlRowSpan) {
    case '1':
    case 1:
      return 'xl:row-span-1';
    case '2':
    case 2:
      return 'xl:row-span-2';
    case '3':
    case 3:
      return 'xl:row-span-3';
    case '4':
    case 4:
      return 'xl:row-span-4';
    case '5':
    case 5:
      return 'xl:row-span-5';
    case '6':
    case 6:
      return 'xl:row-span-6';
    case '7':
    case 7:
      return 'xl:row-span-7';
    case '8':
    case 8:
      return 'xl:row-span-8';
    case '9':
    case 9:
      return 'xl:row-span-9';
    case '10':
    case 10:
      return 'xl:row-span-10';
    case '11':
    case 11:
      return 'xl:row-span-11';
    case '12':
    case 12:
      return 'xl:row-span-12';
    default:
      return '';
  }
});

const className = computed(() => {
  return `${spanClass.value} ${mdSpanClass.value} ${lgSpanClasss.value} ${xlSpanClass.value} ${rowSpanClass.value} ${mdRowSpanClass.value} ${lgRowSpanClass.value} ${xlRowSpanClass.value}`;
});
</script>

<style>
.grid-cell > * {
  height: 100%;
}
</style>
