<template>
  <ModalsContainer :open="open" :submitCallback="onValidate">
    <template #title> {{ title }} </template>
    <template #subtitle> {{ subtitle }} </template>

    <template #left>
      <ModalsIcon :icon="PencilIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-1">
        <SelectBox v-model="theItem" :items="items" :label-props="labelKeys" />
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PencilIcon } from '@heroicons/vue/24/outline';

const open = ref(true);

type Props = {
  title: string;
  subtitle: string;
  currentItem: any;
  labelKeys: string[];
  items: any[];
  callback: (item: any) => void;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const theItem = ref(props.currentItem);

const onValidate = async () => {
  pending.value = true;
  await props.callback(theItem.value);
  pending.value = false;
  open.value = false;
  emit('cancel');
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
