<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="z-overlay relative"
      :initial-focus="imageContainer"
      @close="open = false"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="z-modal fixed inset-0 overflow-y-auto bg-black bg-opacity-60">
        <div
          class="relative flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <XMarkIcon
            class="absolute right-2 top-2 h-10 w-10 cursor-pointer text-white"
            @click="open = false"
          />
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative transform overflow-hidden rounded-md p-2 lg:p-4">
              <div ref="imageContainer" class="">
                <img
                  :src="url"
                  class="object-contain portrait:h-auto portrait:w-[calc(90vw)] landscape:h-[calc(100vh-9rem)] landscape:w-auto"
                />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

const open = ref(true);

type Props = {
  url: string;
};

const props = defineProps<Props>();

const imageContainer = ref<HTMLDivElement>();
</script>
