<template>
  <ModalsContainer
    :open="open"
    :submitCallback="onValidate"
    @close="$emit('cancel')"
    v-if="editedItem"
    :initial-focus="nomInput"
  >
    <template #title> Ajouter une échéance </template>
    <template #subtitle> Entrez les informations concernant cette échéance </template>

    <template #left>
      <ModalsIcon :icon="PencilIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4" ref="nomInput">
        <Grid size="4">
          <GridCell>
            <FormInputContainer label="Code">
              <InputText
                type="text"
                v-model="editedItem.code"
                :required="true"
                id="code-echeance"
              />
            </FormInputContainer>
          </GridCell>
          <GridCell span="2">
            <FormInputContainer label="Nom">
              <InputText
                type="text"
                v-model="editedItem.libelle"
                :required="true"
                id="nom-echeance"
                focus
              />
            </FormInputContainer>
          </GridCell>
          <GridCell>
            <FormInputContainer label="%">
              <InputText
                type="currency"
                currencyText="%"
                v-model="editedItem.pourcentage"
                :required="true"
                id="pourcentage-echeance"
              />
            </FormInputContainer>
          </GridCell>
        </Grid>
        <Grid size="2">
          <GridCell>
            <FormInputContainer
              label="Éch. travaux ?"
              informations="L'échéance correspond à une échéance travaux"
            >
              <InputToggle v-model="editedItem.isTravaux" />
            </FormInputContainer>
          </GridCell>
          <GridCell>
            <FormInputContainer
              label="Remb. DO ?"
              informations="L'échéance doit inclure le montant du remboursement de la DO"
            >
              <InputToggle v-model="editedItem.rembDO" />
            </FormInputContainer>
          </GridCell>
          <GridCell>
            <FormInputContainer
              label="Réception ?"
              informations="L'échéance correspond à l'échéance de réception du chantier"
            >
              <InputToggle v-model="editedItem.livraison" />
            </FormInputContainer>
          </GridCell>
          <GridCell>
            <FormInputContainer
              label="Levée des réserves ?"
              informations="L'échéance correspond à l'échéance de lévée des réserves"
            >
              <InputToggle v-model="editedItem.reserve" />
            </FormInputContainer>
          </GridCell>
        </Grid>
        <p class="text-skin-muted">Délais prévisionnels :</p>
        <div class="flex flex-col gap-2 text-sm" v-if="delais">
          <template v-for="(type, index) in typesFacturation" :key="index">
            <div class="flex items-center gap-2 pr-2 font-medium">
              ({{ type.code }}) {{ type.libelle }} :
            </div>
            <div class="flex items-center justify-end gap-2 leading-none">
              <span class="">+</span
              ><InputText
                type="currency"
                currencyText="S."
                class="small w-[55px]"
                v-model="delais[type.code].delai"
                small
                :required="true"
              />
              <span class="whitespace-nowrap">depuis</span>
              <SelectBox
                v-model="delais[type.code].from"
                :small="true"
                class="w-full"
                :items="Object.values(FromDelaiFacturation)"
              >
              </SelectBox>
            </div>
          </template>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PencilIcon } from '@heroicons/vue/24/outline';
import { FromDelaiFacturation } from '~/types/Enums';
import type { Echeance } from '~/types/models/echeance';
import type { TypeFactu } from '~/types/models/workspace';

const open = ref(true);

type Props = {
  callback: (e: Echeance) => void;
  echeance: Echeance;
  typesFacturation?: TypeFactu[];
};

const props = defineProps<Props>();

const nomInput = ref<HTMLElement>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const editedItem = ref<Echeance>();

const delais = ref<{ [key: string]: { from: string; delai: number } }>();

const onValidate = async () => {
  pending.value = true;
  if (!editedItem.value) return;
  setDelais();
  await props.callback(editedItem.value);
  pending.value = false;
  open.value = false;
  emit('cancel');
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

const getDelais = () => {
  if (!editedItem.value) return {};
  const res: { [key: string]: { from: string; delai: number } } = {};

  props.typesFacturation?.forEach(type => {
    const existing = editedItem.value?.delais?.find(d => d.codeTypeFactu === type.code);
    if (!existing) {
      res[type.code] = {
        delai: 0,
        from: FromDelaiFacturation.OUVERTURE,
      };
    } else {
      res[type.code] = {
        delai: existing.delai,
        from: existing.from,
      };
    }
  });

  return res;
};

const setDelais = () => {
  if (editedItem.value && delais.value) {
    editedItem.value.delais = [];
    props.typesFacturation?.forEach(type => {
      editedItem.value?.delais?.push({
        codeTypeFactu: type.code,
        delai: delais.value![type.code].delai,
        from: delais.value![type.code].from,
      });
    });
  }
};

watch(
  () => props.echeance,
  value => {
    if (value) {
      editedItem.value = JSON.parse(JSON.stringify(value));
      delais.value = getDelais();
    }
  },
  { immediate: true },
);

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
