<template>
  <div class="flex gap-1" :class="className">
    <label
      class="text-skin-base flex items-center gap-2 text-sm font-medium"
      
      v-if="label"
      :class="[labelWrapClass, { 'opacity-30': disabled }]"
      >{{ label }}
      <div v-if="informations">
        <InformationCircleIcon v-tooltip="informations" class="text-skin-heading h-5 w-5" />
      </div>
      <div v-if="$slots.actions"><slot name="actions"></slot></div>
    </label>
    <div :class="[gapClass, { 'mt-1': direction !== 'row' && !!label }]" class="w-full h-full">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { InformationCircleIcon } from '@heroicons/vue/24/outline';

type Props = {
  label?: string;
  spanSize?: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11 | 12 | string;
  verticalAlign?: 'start' | 'center' | 'end';
  direction?: 'row' | 'col';
  nowrap?: boolean;
  gap?: number;
  informations?: string;
  disabled?: boolean;
};

const props = defineProps<Props>();

const spanClass = computed(() => {
  if (props.spanSize) {
    return `sm:col-span-${props.spanSize}`;
  }
  return '';
});

const gapClass = computed(() => {
  switch (props.gap) {
    case 1:
      return 'flex flex-col gap-1';
    case 2:
      return 'flex flex-col gap-2';
    case 3:
      return 'flex flex-col gap-3';
    case 4:
      return 'flex flex-col gap-4';
    case 5:
      return 'flex flex-col gap-5';
    case 6:
      return 'flex flex-col gap-6';
    case 8:
      return 'flex flex-col gap-8';
    default:
      return '';
  }
});

const verticalAlignClass = computed(() => {
  if (props.verticalAlign) {
    return `justify-${props.verticalAlign}`;
  }
  return 'justify-center';
});

const flexDirectionClass = computed(() => {
  if (props.direction === 'row') {
    return 'flex-row gap-4 items-center !justify-start';
  }
  return 'flex-col';
});

const labelWrapClass = computed(() => {
  if (props.nowrap) {
    return 'whitespace-nowrap';
  }
  return '';
});

const className = computed(() => {
  return `${flexDirectionClass.value} ${spanClass.value} ${verticalAlignClass.value}`;
});
</script>
