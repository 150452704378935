<template>
  <ModalsContainer :open="open" :submitCallback="onValidate" @close="$emit('cancel')">
    <template #title> Fusionner deux commandes </template>
    <template #subtitle> Sélectionnez les commandes à fusionner </template>

    <template #left>
      <ModalsIcon :icon="MergeIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <FormInputContainer label="Commande à fusionner">
          <SelectBox
            v-if="commandes.length > 0"
            :items="commandes"
            v-model="selectedCommande"
            :label-props="['projet.code', 'libelle', 'reference']"
            required
          />
          <div v-else>Aucune commande éligible</div>
        </FormInputContainer>
        <div class="flex gap-4">
          <ExclamationTriangleIcon class="text-skin-heading h-7 w-7" />
          <p class="text-skin-muted text-sm">
            La commande sélectionnée sera supprimée et ses lignes seront ajoutées à la nouvelle
            commande.
          </p>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import type { Commande } from '~/types/models/commande';
import MergeIcon from '~/assets/svg/mergeIcon.svg';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';

const open = ref(true);

type Props = {
  callback: (cmd: Commande) => void;
  commandes: Commande[];
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const selectedCommande = ref<Commande | null>(null);

const onValidate = async () => {
  if (!selectedCommande.value) return;
  pending.value = true;
  await props.callback(selectedCommande.value);
  pending.value = false;
  open.value = false;
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
