<template>
  <ModalsContainer :open="open" :submitCallback="onValidate" @close="$emit('cancel')">
    <template #title> Fichiers déja importés </template>
    <template #subtitle> Controle des fichiers déja importés </template>

    <template #left>
      <ModalsIcon :icon="ArrowUpTrayIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-6">
        <div>
          <p class="text-base font-medium">
            Les fichiers suivants ont déjà été importés. Vous pouvez néanmoins les importer à nouveau
            si vous le souhaitez.
          </p>
          <br />
          <p class="text-sm">Veuillez sélectionner les fichiers que vous souhaitez réellement importer.</p>
        </div>
        <div class="mb-4 flex flex-col gap-2">
          <template v-for="(file, index) in files" :key="index">
            <Checkbox
              id="useNotiveForTravaux"
              :checked="!!selectedFiles?.find(f => f.name === file.name)"
              :label="file.name"
              @update:checked="toggleFile(file)"
              size="sm" />
          </template>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Continuer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { ArrowUpTrayIcon } from '@heroicons/vue/24/outline';

const open = ref(true);

type Props = {
  callback: (files: File[]) => void;
  files: File[];
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const selectedFiles = ref<File[]>(props.files);

const toggleFile = (file: File) => {
  if (selectedFiles.value.find(f => f.name === file.name)) {
    selectedFiles.value = selectedFiles.value.filter(f => f.name !== file.name);
  } else {
    selectedFiles.value = [...selectedFiles.value, file];
  }
};

const onValidate = async () => {
  pending.value = true;
  await props.callback(selectedFiles.value);
  pending.value = false;
  open.value = false;
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
