<template>
  <input
    ref="inputRef"
    type="text"
    class="input"
    @blur="onBlur"
    @keydown="onKeyDown"
    :class="{ small: small }" />
</template>

<script setup lang="ts">
import { useCurrencyInput } from 'vue-currency-input';
import type { CurrencyInputOptions } from 'vue-currency-input';

type Props = {
  modelValue: string | number | null | undefined;
  options: CurrencyInputOptions;
  small?: boolean;
};

const props = defineProps<Props>();

const { inputRef, setOptions, setValue, numberValue } = useCurrencyInput(props.options, false);

const emit = defineEmits(['update:modelValue', 'change']);

watch(
  () => props.modelValue,
  value => {
    if (value === null || value === undefined || value === '') {
      setValue(null);
      return;
    }
    if (typeof value === 'string') {
      setValue(parseFloat(value));
      return;
    }
    setValue(value);
  },
);

watch(
  () => props.options,
  options => {
    setOptions(options);
  },
);

const onBlur = () => {
  if (props.modelValue === numberValue.value) return;
  emit('update:modelValue', numberValue.value);
};

const onKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    e.stopPropagation();
    inputRef.value?.blur();
  }
};
</script>
