<template>
  <ModalsContainer :open="open" :submitCallback="onValidate" @close="$emit('cancel')">
    <template #title> Ajouter une ligne de commande </template>
    <template #subtitle>
      Choissisez le sous lot et entrez la description, la quantité et le prix unitaire
    </template>

    <template #left>
      <ModalsIcon :icon="PlusIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <FormInputContainer label="Sous-lot" v-if="sousLots?.length">
          <SelectBox
            :items="sousLots"
            v-model="newLigne.sousLot"
            :label-props="['code', 'libelle']"
            id-field="id"
            required
          />
        </FormInputContainer>
        <FormInputContainer label="Description">
          <InputTextarea autogrow v-model="newLigne.description" required focus />
        </FormInputContainer>
        <Grid size="2">
          <GridCell>
            <FormInputContainer label="Quantité">
              <InputText v-model="newLigne.quantite" type="number" />
            </FormInputContainer>
          </GridCell>
          <GridCell>
            <FormInputContainer label="Prix unitaire HT (€)">
              <CurrencyInput :options="{ currency: 'EUR' }" v-model="newLigne.prixUnitaire" />
            </FormInputContainer>
          </GridCell>
        </Grid>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PlusIcon } from '@heroicons/vue/24/outline';
import type { CommandeLigne } from '~/types/models/commandeLigne';

const open = ref(true);

type Props = {
  callback: (ligne: CommandeLigne) => void;
  sousLots: any[];
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const newLigne = ref({
  description: '',
  descriptionComplementaire: '',
  gamme: '',
  unite: '',
  id: -1,
  prix: 0,
  quantite: 0,
  prixUnitaire: 0,
  sousLot: null,
} as CommandeLigne);

const onValidate = async () => {
  if (!newLigne.value.description) return;
  pending.value = true;
  newLigne.value.prix = newLigne.value.prixUnitaire * newLigne.value.quantite;
  await props.callback(newLigne.value);
  pending.value = false;
  open.value = false;
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
