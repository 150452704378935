<template>
  <ModalsContainer :open="open" :submitCallback="onValidate">
    <template #title> {{ title }} </template>
    <template #subtitle> {{ subtitle }} </template>

    <template #left>
      <ModalsIcon :icon="PencilIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <FormInputContainer label="Date:" direction="row">
          <InputDate v-model="theDate" />
        </FormInputContainer>
        <FormInputContainer label="Montant:" direction="row">
          <CurrencyInput v-model="theMontant" :options="{ currency: 'EUR' }" />
        </FormInputContainer>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PencilIcon } from '@heroicons/vue/24/outline';

const open = ref(true);

type Props = {
  title: string;
  subtitle: string;
  currentDate: string;
  currentMontant: number;
  callback: (date: string, montant: number) => void;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const theDate = ref(useDateFormat(props.currentDate, 'yyyy-MM-dd'));
const theMontant = ref(props.currentMontant);

const onValidate = async () => {
  if (!theDate.value) return;
  if (theMontant.value === null || theMontant.value === undefined) return;
  pending.value = true;
  await props.callback(theDate.value, theMontant.value);
  pending.value = false;
  open.value = false;
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
