<template>
  <TransitionRoot as="template" :show="pending.value">
    <Dialog as="div" class="z-overlay relative" :initial-focus="loadingTextContainer">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="z-modal fixed inset-0 overflow-y-auto bg-black bg-opacity-60">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="bg-skin-fill-2 relative overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="sm:flex sm:items-center">
                <div
                  class="bg-skin-fill mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-opacity-20 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <Spinner class="!text-skin-heading !h-full !w-full" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="text-skin-heading text-lg font-medium leading-6"
                    ref="loadingTextContainer"
                  >
                    {{ title }}
                  </DialogTitle>
                  <div v-if="rawMessage" class="text-skin-muted text-sm">
                    {{ rawMessage }}
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

const open = ref(true);

type Props = {
  title: string;
  message?: Ref<string> | string;
  pending: Ref<any>;
};

const props = defineProps<Props>();

const loadingTextContainer = ref<HTMLElement | null>(null);

const rawMessage = computed(() => {
  if (typeof props.message === 'string') {
    return props.message;
  }
  return props.message?.value;
});
</script>
