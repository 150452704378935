import { DateTime } from 'luxon';

const extractPropertiesValue = (item: any, prop: string, returnArray?: boolean) => {
  return prop.split('.').reduce((a, b) => {
    const v: any = a[b];
    const rawV = toRaw(v);
    if (rawV === null || rawV === undefined) return '';
    if (Array.isArray(rawV)) {
      if (returnArray) return v;
      return v.length ? v[0] : '';
    }
    return v;
  }, item);
};

const combinedExtractedPropertiesValue = (
  item: any,
  props: string[],
  separator = '',
  emptyPlaceholder = '-',
) => {
  const sepWithWhiteSpaces = separator && separator !== ' ' ? ` ${separator} ` : separator;
  const combinedValue = props.reduce((p, c) => {
    // c can be of the form [prop1.prop2,prop3.prop4] or prop1.prop2
    const regExp = /\[(.*?)\]/;
    const match = c.match(regExp);
    let value = '';
    if (match) {
      const possibleProps = match[1].split(',');
      if (possibleProps.length > 0) {
        value = extractPropertiesValue(item, possibleProps[0]);
        if (!value && possibleProps.length > 1) {
          value = extractPropertiesValue(item, possibleProps[1]);
        }
      }
    } else {
      value = extractPropertiesValue(item, c);
    }

    const result = `${p}${p ? sepWithWhiteSpaces : ''}${value}`;

    return result;
  }, '');

  return combinedValue || emptyPlaceholder;
};

const flattenedCollection = (collection: object[], childrenKey: string) => {
  const result: any[] = [];
  collection.forEach((item) => {
    result.push(item);
    const childs: any[] = item[childrenKey];
    if (childs && childs.length) result.push(...flattenedCollection(childs, childrenKey));
  });
  return result;
};

const makeParentChildTreeFromCollection = (
  collection: any[],
  idField: string,
  parentField: string,
  childrenField: string,
) => {
  const result: any[] = [];
  // sort collection by parent. Parent null first.
  collection.sort((a, b) => {
    const aParent = a[parentField];
    const bParent = b[parentField];
    if (!aParent && bParent) return -1;
    if (aParent && !bParent) return 1;
    return 0;
  });

  collection.forEach((item) => {
    const isParent = !item[parentField];
    if (isParent) {
      const id = item[idField];
      if (result.findIndex((l) => l[idField] === id) === -1) {
        result.push(item);
      }
    } else {
      const id = item[parentField][idField];
      const alreadyInsertedParent = result.find((l) => l[idField] === id);
      if (alreadyInsertedParent) {
        if (!alreadyInsertedParent[childrenField]) alreadyInsertedParent[childrenField] = [];
        if (!alreadyInsertedParent[childrenField].find((i: any) => i[idField] === item[idField]))
          alreadyInsertedParent[childrenField].push(item);
        else {
          const index = alreadyInsertedParent[childrenField].findIndex(
            (i: any) => i[idField] === item[idField],
          );
          alreadyInsertedParent[childrenField][index] = item;
        }
      }
    }
  });
  return result;
};

const getValueFromItemForKeys = (
  item: any,
  keys: Array<string> | Function | undefined,
  separator = '-',
  uppercase = false,
  isObject = false,
) => {
  let value;
  if (typeof keys === 'function') {
    value = keys(item);
  } else if (keys) {
    if (!isObject) {
      value = keys.reduce((p, c) => {
        return `${p}${p ? ' ' + separator + ' ' : ''}${extractPropertiesValue(item, c)}`;
      }, '');
    } else {
      value = keys.reduce((p, c) => {
        return (item as any)[c];
      }, item);
    }
  }
  return isObject ? value : uppercase ? value.toUpperCase() : value;
};

const isDateAfterToday = (date?: string | null) => {
  if (!date) return undefined;
  const today = DateTime.now();
  const dateToCompare = DateTime.fromISO(date);
  return dateToCompare > today;
};

export const useUtils = {
  extractPropertiesValue,
  combinedExtractedPropertiesValue,
  flattenedCollection,
  makeParentChildTreeFromCollection,
  getValueFromItemForKeys,
  isDateAfterToday,
};

export const getRangeIndex = (array: any[], nb: number, index: number) => {
  const start = index - nb < 0 ? 0 : index - nb;
  const end = index + nb > array.length - 1 ? array.length - 1 : index + nb;

  return [start, end];
};

export const getWeekNumber = (date: string | DateTime) => {
  if (!date) return 1;
  const d = typeof date === 'string' ? DateTime.fromISO(date) : date;
  if (d.year === d.weekYear) return d.localWeekNumber;
  return d.weeksInLocalWeekYear;
};

export function percentageToColor(percentage: number) {
  // Ensure the percentage is between 0 and 100
  const safePercentage = Math.min(100, Math.max(0, percentage));

  // Hue value for red in HSL is 0 (or 360) and for green is 120
  // We calculate the hue value based on the percentage
  const hue = (120 * safePercentage) / 100;

  // You can adjust saturation and lightness as per your liking
  // Saturation at 100% makes the color fully vivid, and lightness at 50% ensures
  // we stay away from too dark or too light, avoiding brownish tones effectively.
  const saturation = 80;
  const lightness = 50;

  // Convert HSL to the desired HEX format. No direct JavaScript function does this,
  // so we first convert HSL to RGB, then to HEX.
  const rgb = hslToRgb(hue, saturation, lightness);
  const hex = rgbToHex(rgb.r, rgb.g, rgb.b);

  return hex;
}

function hslToRgb(h: number, s: number, l: number) {
  s /= 100;
  l /= 100;

  const k = (n: number) => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));

  // Convert to RGB values in the range [0, 255]
  return {
    r: Math.round(255 * f(0)),
    g: Math.round(255 * f(8)),
    b: Math.round(255 * f(4)),
  };
}

function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

function rgbToHex(r: number, g: number, b: number) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const compareDate = (date1?: string, date2?: string) => {
  if (!date1 && !date2) return 0;
  if (!date1) return 1;
  if (!date2) return -1;
  const d1 = DateTime.fromISO(date1);
  const d2 = DateTime.fromISO(date2);
  return d1.toMillis() - d2.toMillis();
};

export const surroundWithDoubleQuotes = (str: string) => `"${str || ''}"`;

export const escapeDoubleQuotes = (str: string) =>
  str && typeof str === 'string' ? str.replace(/"/g, '""').replace(/\r/g, '') : str || '';
