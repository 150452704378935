<template>
  <ModalsContainer :open="open" @close="onClose" :submit-callback="onValidate">
    <template #title> Ajouter un document </template>
    <template #subtitle> Choisissez le dossier et ajouter un document </template>

    <template #left>
      <ModalsIcon :icon="DocumentArrowUpIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <FormInputContainer label="Dossier" :gap="2">
          <div class="flex items-center gap-4">
            <SelectBox
              class="grow"
              :items="foldersWithEmptyValue"
              v-model="selectedFolder"
              @update:model-value="missingFolderError = false"
              capitalize
              :value-field="'value'"
            />
            <PlusCircleIcon
              class="text-skin-heading h-6 w-6 shrink-0 cursor-pointer"
              @click.stop="showNewFolder"
            />
          </div>
          <div class="flex items-center gap-4" v-if="showNewFolderInput">
            <InputText
              v-model="newFolderLabel"
              class="grow"
              placeholder="Entrez le nom du nouveau dossier"
              @update:model-value="missingFolderError = false"
              @blur="fixFolderName"
            />
            <MinusCircleIcon
              class="text-skin-danger h-6 w-6 shrink-0 cursor-pointer"
              @click.stop="removeNewFolder"
            />
          </div>
          <div v-if="missingFolderError" class="text-skin-danger text-sm">
            Veuillez sélectionner le dossier
          </div>
        </FormInputContainer>

        <div v-if="toUploadFiles.length">
          <div v-for="(file, index) in toUploadFiles" :key="index" class="flex gap-4">
            <DocumentIcon class="h-5 w-5 shrink-0" />
            <span class="text-sm">{{ file.name }}</span>
          </div>
        </div>
        <InputMediaDnd @file-selected="handleFileSelected" />
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button variant="muted" @click="open = false" :disabled="pending">Annuler</Button>
        <Button
          type="submit"
          :loading="pending"
          :disabled="pending || !toUploadFiles.length"
          v-tooltip="!toUploadFiles.length ? 'Ajoutez un document' : ''"
          >Ajouter</Button
        >
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import {
  DocumentArrowUpIcon,
  DocumentIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from '@heroicons/vue/24/outline';

import { ref } from 'vue';

const open = ref(true);

type Props = {
  folders: string[];
  callback: (file: File[], folder: string) => void;
};

const missingFolderError = ref(false);
const toUploadFiles = ref<File[]>([]);
const selectedFolder = ref<string>();
const showNewFolderInput = ref(false);
const newFolderLabel = ref<string>();

const finalFolderValue = computed(() => {
  return newFolderLabel.value
    ? newFolderLabel.value.trim().toLowerCase()
    : selectedFolder.value?.toLowerCase();
});

// wtach newFolderLabel
watch(
  () => newFolderLabel.value,
  () => {
    if (newFolderLabel.value) {
      selectedFolder.value = '';
    }
  },
);

const props = defineProps<Props>();
const pending = ref(false);

const foldersWithEmptyValue = computed(() => {
  const res = props.folders;
  if (!res.length) res.push('');

  return res.map(folder => {
    if (folder === '') return { id: null, name: 'Aucun', value: '' };
    const id = folder;

    const name = '&#128193;&emsp;' + folder.replaceAll('-', ' / ');
    return { id, name, value: folder };
  });
});

const showNewFolder = () => {
  showNewFolderInput.value = true;
  newFolderLabel.value = '';
};

const removeNewFolder = () => {
  showNewFolderInput.value = false;
  newFolderLabel.value = '';
};

const fixFolderName = () => {
  newFolderLabel.value = newFolderLabel.value
    ?.replaceAll(' - ', '-')
    .replaceAll(' / ', '-')
    .replaceAll(' -', '-')
    .replaceAll('- ', '-')
    .replaceAll('/ ', '-')
    .replaceAll(' /', '-')
    .replaceAll('/', '-');
};

const onValidate = async () => {
  if (!finalFolderValue.value) missingFolderError.value = true;
  else if (toUploadFiles.value.length) {
    pending.value = true;
    await props.callback(toUploadFiles.value, finalFolderValue.value);
    open.value = false;
  }
};

const handleFileSelected = (files: File[]) => {
  toUploadFiles.value = files;
};

const onClose = (e: Event) => {
  open.value = false;
};
</script>
