<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="z-overlay relative" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="z-modal fixed inset-0 overflow-y-auto bg-black bg-opacity-60">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="bg-skin-fill-2 relative overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="sm:flex sm:items-center">
                <div
                  class="bg-skin-fill mx-auto mb-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-opacity-20 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <CloudArrowUpIcon class="text-skin-heading !h-full !w-full" />
                </div>
                <form
                  @submit.prevent="onValidate"
                  class="mt-3 flex w-full flex-col gap-6 text-center sm:mt-0 sm:ml-4 sm:text-left"
                >
                  <DialogTitle as="h3" class="text-skin-heading text-lg font-medium leading-6">
                    Sauvegarder la vue actuelle.
                    <div class="text-skin-muted text-sm">
                      Cela inclus les filtres actifs et les colonnes affichées.
                    </div>
                  </DialogTitle>

                  <div class="flex flex-col gap-1">
                    <span class="text-skin-heading text-sm">Entrez un nom pour la vue :</span>
                    <InputText v-model="name" />
                    <Checkbox
                      id="isDefault"
                      label="Utiliser par défaut"
                      :checked="isDefault"
                      @update:checked="isDefault = $event"
                      size="sm"
                      class="text-skin-muted"
                    />
                  </div>

                  <div class="mt-5 gap-2 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <Button type="submit" :disabled="pending">Valider</Button>
                    <Button type="reset" variant="muted" @click="open = false">Annuler</Button>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { CloudArrowUpIcon } from '@heroicons/vue/24/outline';

const open = ref(true);

type Props = {
  callback: (config: { name: string; isDefault: boolean }) => void;
  currentConfig?: any;
};

const props = defineProps<Props>();
const name = ref(props.currentConfig?.name || '');
const isDefault = ref(props.currentConfig?.isDefault || false);

const pending = ref(false);

const onValidate = async () => {
  pending.value = true;
  open.value = false;
  await props.callback({ name: name.value, isDefault: isDefault.value });
};
</script>
