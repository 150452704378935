import { defineStore } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

const compareVersions = (va: string, vb: string) => {
  const [majorA, minorA, patchA] = va.split('.');
  const [majorB, minorB, patchB] = vb.split('.');

  const [intMajorA, intMinorA, intPatchA] = [parseInt(majorA), parseInt(minorA), parseInt(patchA)];
  const [intMajorB, intMinorB, intPatchB] = [parseInt(majorB), parseInt(minorB), parseInt(patchB)];

  if (isNaN(intMajorA) || isNaN(intMinorA) || isNaN(intPatchA)) return 0;
  if (isNaN(intMajorB) || isNaN(intMinorB) || isNaN(intPatchB)) return 0;

  if (parseInt(majorA) > parseInt(majorB)) return 1;
  if (parseInt(majorA) < parseInt(majorB)) return -1;

  if (parseInt(minorA) > parseInt(minorB)) return 1;
  if (parseInt(minorA) < parseInt(minorB)) return -1;

  if (parseInt(patchA) > parseInt(patchB)) return 1;
  if (parseInt(patchA) < parseInt(patchB)) return -1;

  return 0;
};

export const useAppStore = defineStore('app', {
  state: () => ({
    socketConnexionStatus: false,
    appVersion: useRuntimeConfig().public.APP_VERSION,
    appVersionFromServer: '',
    scroller: null as HTMLElement | null,
    sideBarExpanded: true,
    mobileVerticalMenuVisible: false,
    pageTitle: '',
    pageDescription: '',
    previousRouteFullPath: '',
    currentRoute: null as RouteLocationNormalized | null,
  }),
  getters: {
    needRefreshClient: (state) => {
      if (compareVersions(state.appVersion, state.appVersionFromServer) < 0) {
        return true;
      }

      return false;
    },

    needWaitServerUpgrade: (state) => {
      if (compareVersions(state.appVersion, state.appVersionFromServer) > 0) {
        return true;
      }

      return false;
    },
  },
  actions: {
    setSocketConnexionStatus(status: boolean) {
      this.socketConnexionStatus = status;
    },

    setVersionFromServer(version: string) {
      this.appVersionFromServer = version;
    },

    toggleSideBar() {
      this.sideBarExpanded = !this.sideBarExpanded;
    },
    toggleMobileVerticalMenu() {
      this.mobileVerticalMenuVisible = !this.mobileVerticalMenuVisible;
    },
    closeMobileVerticalMenu() {
      this.mobileVerticalMenuVisible = false;
    },
    setScroller(scroller: HTMLElement) {
      this.scroller = scroller;
    },
    setPageTitle(title: string) {
      this.pageTitle = title;
    },
    setPageDescription(description: string) {
      this.pageDescription = description;
    },
    setPreviousRouteFullPath(path: string) {
      this.previousRouteFullPath = path;
    },
    setCurrentRoute(route: RouteLocationNormalized) {
      this.currentRoute = route;
    },
  },
});
