<template>
  <div class="grid" :class="className">
    <slot />
  </div>
</template>

<script setup lang="ts">
type GridSizes =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;
type GapSizes =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;
type Props = {
  size?: GridSizes;
  mdSize?: GridSizes;
  lgSize?: GridSizes;
  xlSize?: GridSizes;
  gap?: GapSizes;
};

const props = withDefaults(defineProps<Props>(), {
  size: '4',
  gap: '4',
});

const sizeClass = computed(() => {
  switch (props.size) {
    case '1':
    case 1:
      return 'sm:grid-cols-1';
    case '2':
    case 2:
      return 'sm:grid-cols-2';
    case '3':
    case 3:
      return 'sm:grid-cols-3';
    case '4':
    case 4:
      return 'sm:grid-cols-4';
    case '5':
    case 5:
      return 'sm:grid-cols-5';
    case '6':
    case 6:
      return 'sm:grid-cols-6';
    case '7':
    case 7:
      return 'sm:grid-cols-7';
    case '8':
    case 8:
      return 'sm:grid-cols-8';
    case '9':
    case 9:
      return 'sm:grid-cols-9';
    case '10':
    case 10:
      return 'sm:grid-cols-10';
    case '11':
    case 11:
      return 'sm:grid-cols-11';
    case '12':
    case 12:
      return 'sm:grid-cols-12';
    default:
      return 'sm:grid-cols-4';
  }
});

const mdSizeClass = computed(() => {
  switch (props.mdSize) {
    case '1':
    case 1:
      return 'md:grid-cols-1';
    case '2':
    case 2:
      return 'md:grid-cols-2';
    case '3':
    case 3:
      return 'md:grid-cols-3';
    case '4':
    case 4:
      return 'md:grid-cols-4';
    case '5':
    case 5:
      return 'md:grid-cols-5';
    case '6':
    case 6:
      return 'md:grid-cols-6';
    case '7':
    case 7:
      return 'md:grid-cols-7';
    case '8':
    case 8:
      return 'md:grid-cols-8';
    case '9':
    case 9:
      return 'md:grid-cols-9';
    case '10':
    case 10:
      return 'md:grid-cols-10';
    case '11':
    case 11:
      return 'md:grid-cols-11';
    case '12':
    case 12:
      return 'md:grid-cols-12';
    default:
      return '';
  }
});

const lgSizeClass = computed(() => {
  switch (props.lgSize) {
    case '1':
    case 1:
      return 'lg:grid-cols-1';
    case '2':
    case 2:
      return 'lg:grid-cols-2';
    case '3':
    case 3:
      return 'lg:grid-cols-3';
    case '4':
    case 4:
      return 'lg:grid-cols-4';
    case '5':
    case 5:
      return 'lg:grid-cols-5';
    case '6':
    case 6:
      return 'lg:grid-cols-6';
    case '7':
    case 7:
      return 'lg:grid-cols-7';
    case '8':
    case 8:
      return 'lg:grid-cols-8';
    case '9':
    case 9:
      return 'lg:grid-cols-9';
    case '10':
    case 10:
      return 'lg:grid-cols-10';
    case '11':
    case 11:
      return 'lg:grid-cols-11';
    case '12':
    case 12:
      return 'lg:grid-cols-12';
    default:
      return '';
  }
});

const xlSizeClass = computed(() => {
  switch (props.xlSize) {
    case '1':
    case 1:
      return 'xl:grid-cols-1';
    case '2':
    case 2:
      return 'xl:grid-cols-2';
    case '3':
    case 3:
      return 'xl:grid-cols-3';
    case '4':
    case 4:
      return 'xl:grid-cols-4';
    case '5':
    case 5:
      return 'xl:grid-cols-5';
    case '6':
    case 6:
      return 'xl:grid-cols-6';
    case '7':
    case 7:
      return 'xl:grid-cols-7';
    case '8':
    case 8:
      return 'xl:grid-cols-8';
    case '9':
    case 9:
      return 'xl:grid-cols-9';
    case '10':
    case 10:
      return 'xl:grid-cols-10';
    case '11':
    case 11:
      return 'xl:grid-cols-11';
    case '12':
    case 12:
      return 'xl:grid-cols-12';
    default:
      return '';
  }
});

const gapClass = computed(() => {
  switch (props.gap) {
    case '0':
    case 0:
      return 'gap-0';
    case '1':
    case 1:
      return 'gap-1';
    case '2':
    case 2:
      return 'gap-2';
    case '3':
    case 3:
      return 'gap-3';
    case '4':
    case 4:
      return 'gap-4';
    case '5':
    case 5:
      return 'gap-5';
    case '6':
    case 6:
      return 'gap-6';
    case '7':
    case 7:
      return 'gap-7';
    case '8':
    case 8:
      return 'gap-8';
    case '9':
    case 9:
      return 'gap-9';
    case '10':
    case 10:
      return 'gap-10';
    case '11':
    case 11:
      return 'gap-11';
    case '12':
    case 12:
      return 'gap-12';
    default:
      return '';
  }
});

const className = computed(() => {
  return `${sizeClass.value} ${mdSizeClass.value} ${lgSizeClass.value} ${xlSizeClass.value} ${gapClass.value}`;
});
</script>
