<template>
  <div class="text-skin-base theme-default h-screen w-screen">
    <NuxtLoadingIndicator color="#E88A1A" />
    <NuxtPage />
  </div>
</template>

<script setup lang="ts">
import type { NuxtAppManifestMeta } from '#app';

useHead({
  bodyAttrs: {
    class: 'theme-default',
  },
});

const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let tid: any;
  return function (...args: any[]) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};

const _ = (window as any).ResizeObserver;
(window as any).ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce(callback, 20);
    super(callback);
  }
};

const nuxtApp = useNuxtApp();
nuxtApp.hook('app:manifest:update', (meta?: NuxtAppManifestMeta) => {
  reloadNuxtApp();
});
</script>
