import { defineNuxtPlugin } from '#app';
import { useToast } from 'vue-toastification';

export default defineNuxtPlugin((nuxtApp) => {
  const toast = useToast();

  nuxtApp.hooks.hook('strapi:error', (e) => {
    console.error(e.error);
    switch (e.error.status) {
      case 401:
        window.location.href = '/login';
        break;
      case 403:
        window.location.href = '/login';
        break;
      default:
        toast.error('une erreur est survenue');
    }
  });
});
