<template>
  <ModalsContainer :open="open" @close="onClose" :submit-callback="onValidate">
    <template #title> {{ title || 'Ajouter un document' }} </template>
    <template #subtitle> {{ subtitle || 'Choisissez le type et ajouter un document' }} </template>

    <template #left>
      <ModalsIcon :icon="DocumentArrowUpIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <SelectBox
          :items="types"
          v-model="selectedType"
          required
          :label-props="['label']"
          id-field="name"
          @update:model-value="missingTypeError = false"
        />
        <div v-if="missingTypeError" class="text-skin-danger text-sm">
          Veuillez sélectionner le type de document
        </div>
        <div v-if="toUploadFiles.length">
          <div v-for="(file, index) in toUploadFiles" :key="index" class="flex gap-4">
            <DocumentIcon class="h-5 w-5 shrink-0" />
            <span class="text-sm">{{ file.name }}</span>
          </div>
        </div>
        <InputMediaDnd @file-selected="handleFileSelected" />
        <div>
          <FormInputContainer
            label="Date de validité"
            v-if="selectedType && (selectedType as any).validite"
          >
            <InputText type="date" v-model="dateValidite" required />
          </FormInputContainer>
        </div>
        <div v-for="m in metas" :key="m.name">
          <FormInputContainer :label="m.label">
            <InputText v-model="m.value" required />
          </FormInputContainer>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="close()" :disabled="pending">Annuler</Button>
        <Button
          type="submit"
          :loading="pending"
          :disabled="pending || !toUploadFiles.length"
          v-tooltip="!toUploadFiles.length ? 'Ajoutez un document' : ''"
          >Ajouter</Button
        >
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { DocumentArrowUpIcon, DocumentIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import { DocumentType } from '~/documents/types';
import type { DocMetadata } from '~/types/models/document';

const open = ref(true);

type Props = {
  types: DocumentType[];
  callback: (file: File[], type: string, validite?: string, metas?: DocMetadata[]) => void;
  preType?: DocumentType;
  opened?: boolean;
  title?: string;
  subtitle?: string;
};

const props = withDefaults(defineProps<Props>(), {
  opened: true,
});

watch(
  () => props.opened,
  opened => {
    open.value = opened;
  },
  { immediate: true },
);

const emit = defineEmits(['close']);

const missingTypeError = ref(false);

const toUploadFiles = ref<File[]>([]);
const selectedType = ref<DocumentType>();

const pending = ref(false);

const dateValidite = ref('');

const metas = computed<DocMetadata[]>(() => {
  const metas = selectedType.value ? (selectedType.value as any).metas : null;
  return metas ? JSON.parse(JSON.stringify(metas)) : [];
});

const contentWrapper = ref<HTMLElement>();

// watch selectedType change and focus on the modal
watch(
  () => selectedType.value,
  () => {
    if (selectedType.value) {
      contentWrapper.value?.focus();
    }
  },
);

watch(
  () => props.preType,
  preType => {
    if (preType) selectedType.value = preType;
  },
  { immediate: true },
);

const onValidate = async () => {
  if (!selectedType.value) missingTypeError.value = true;
  else if (toUploadFiles.value.length) {
    pending.value = true;
    await props.callback(
      toUploadFiles.value,
      selectedType.value.name,
      dateValidite.value,
      metas.value,
    );
    close();
  }
};

const handleFileSelected = (files: File[]) => {
  toUploadFiles.value = files;
};

const close = () => {
  emit('close');
  open.value = false;
  pending.value = false;
  toUploadFiles.value = [];
  selectedType.value = undefined;
  dateValidite.value = '';
  missingTypeError.value = false;
};

const onClose = (e: Event) => {
  close();
};
</script>
