const roles = {
  CS_SUPER_ADMIN: 'cs_super_admin',
  CS_ADMIN: 'cs_admin',
  RM_ADMIN: 'rm_admin',
  RM_COMMERCE: 'rm_commerce',
  RM_COMMERCE_EXT: 'rm_commerce_ext',
  CS_METREUR: 'cs_metreur',
  CS_METREUR_EXT: 'cs_metreur_ext',
  CS_ASSISTANT: 'cs_assistant',
  CS_RESPBE: 'cs_respbe',
  CS_HOMEPLANNER: 'cs_homeplanner',
  CS_CONDUC: 'cs_conduc',
  CS_CONDUC_EXT: 'cs_conduc_ext',
  CS_RESPCOM: 'cs_respcom',
  CS_RESP_TVX: 'cs_resp_tvx',
  CS_DESSINATEUR: 'cs_dessinateur',
};

const rolesDatas = {
  cs_super_admin: {
    label: 'Super Administrateur',
    description:
      "Acces à toutes les fonctionnalités de la marque et a tous les paramètres de l'application",
    mandatoryFields: [],
  },
  cs_admin: {
    label: 'Administrateur',
    description: 'Acces à toutes les fonctionnalités de la marque',
    mandatoryFields: [],
  },
  rm_admin: { label: 'Administrateur Marque', description: '', mandatoryFields: ['representant'] },
  rm_commerce: {
    label: 'Commercial',
    description: '',
    mandatoryFields: ['representant', 'agence'],
  },
  rm_commerce_ext: {
    label: 'Commercial Externe',
    description: '',
    mandatoryFields: ['representant', 'agence'],
  },
  cs_metreur: { label: 'Métreur', description: '', mandatoryFields: [] },
  cs_metreur_ext: { label: 'Métreur Externe', description: '', mandatoryFields: [] },
  cs_assistant: { label: 'Assistant de direction', description: '', mandatoryFields: [] },
  cs_respbe: { label: 'Responsable BE', description: '', mandatoryFields: [] },
  cs_homeplanner: { label: 'Home Planner', description: '', mandatoryFields: [] },
  cs_conduc: { label: 'Conducteur de travaux', description: '', mandatoryFields: [] },
  cs_conduc_ext: { label: 'Conducteur de travaux Externe', description: '', mandatoryFields: [] },
  cs_respcom: { label: 'Responsable Commercial', description: '', mandatoryFields: [] },
  cs_resp_tvx: { label: 'Responsable Travaux', description: '', mandatoryFields: [] },
  cs_dessinateur: { label: 'Dessinateur', description: '', mandatoryFields: [] },
};

const avancements = {
  ANNULE: 'annule',
  CREE: 'cree',
  CCT: 'cct',
  DEMANDE_AVP: 'demande-avp',
  FP_EN_COURS: 'fp-en-cours',
  FP_DISPONIBLE: 'fp-disponible',
  FP_PRESENTEE: 'fp-presentee',
  ND_DEMANDEE: 'nd-demandee',
  ND_EN_COURS: 'nd-en-cours',
  ND_DISPONIBLE: 'nd-disponible',
  ND_PRESENTEE: 'nd-presentee',
  CONTRAT_SIGNE: 'contrat-signe',
  VALIDATION_ADV: 'validation-adv',
  RAR: 'rar',
  LEVEE_COND_SUSP: 'lev-cond-susp',
  PREPA_CHANTIER: 'prepa-chantier',
  TRAVAUX: 'travaux',
  GARANTIES: 'garanties',
};

const statuts = {
  AVP: 'AVP',
  CD: 'CD',
  ANN: 'ANN',
  TVX: 'TVX',
  SAV: 'SAV',
};

const civilites = {
  MME: 'Mme.',
  M: 'M.',
};

const clientTypes = {
  PART: 'PART',
  PRO: 'PRO',
};

const clientTypesLabels = {
  PART: 'Particulier',
  PRO: 'Professionel',
};

const DESCRIPTION_LIGNE_DEVIS = 'Selon devis ci-joint';
const NA = 'n/a';

export default {
  roles,
  rolesDatas,
  avancements,
  civilites,
  clientTypes,
  clientTypesLabels,
  statuts,
  DESCRIPTION_LIGNE_DEVIS,
  NA,
};
