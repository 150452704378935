<template>
  <div class="flex items-center" @click.stop="onClick">
    <DatasTableBadge
      :color-classes="withBg ? 'bg-skin-fill border border-skin-border-dark' : '!p-0'">
      <div class="flex items-center">
        <input
          :id="(id as string)"
          :name="(id as string)"
          :checked="checked"
          :disabled="disabled"
          type="checkbox"
          @click.stop="onClick"
          class="h-4 w-4 cursor-pointer rounded-md border-skin-border-dark bg-skin-fill-2 !ring-0 checked:bg-skin-accent checked:outline-none hover:bg-skin-fill-3 hover:bg-opacity-30 checked:hover:bg-skin-accent focus:shadow-none focus:outline-none checked:focus:bg-skin-accent disabled:bg-skin-fill-3 disabled:bg-opacity-60 hover:disabled:bg-skin-fill-3 hover:disabled:bg-opacity-60"
          @change="emit('update:checked', ($event.target as HTMLInputElement).checked)" />
        <label
          v-if="label"
          :for="(id as string)"
          class="mb-0 ml-2 cursor-pointer select-none font-medium"
          :class="[textSizeClass, labelClass]"
          ><slot>{{ label }}</slot></label
        >
      </div>
    </DatasTableBadge>
  </div>
</template>

<script setup lang="ts">
type Props = {
  id: string | number;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  withBg?: boolean;
  size?: 'xs' | 'sm' | 'lg' | 'xl';
  labelClass?: string;
};

const props = withDefaults(defineProps<Props>(), {
  size: 'sm',
});

type Emit = {
  (event: 'update:checked', value: any): void;
};
const emit = defineEmits<Emit>();

const textSizeClass = computed(() => {
  if (props.size) {
    return `text-${props.size}`;
  }
});

const onClick = (e: MouseEvent) => {
  e.stopPropagation();
};
</script>

<style lang="scss" scoped></style>
