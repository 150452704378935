<template>
  <div class="flex items-center space-x-4">
    <div v-for="item in items" :key="item.value" class="flex cursor-pointer items-center">
      <input
        :id="item.value"
        :value="item.value"
        :name="id"
        type="radio"
        v-model="selectedValue"
        class="border-skin-border-dark text-skin-accent focus:ring-skin-accent h-4 w-4 disabled:cursor-not-allowed"
        :disabled="disabled"
      />
      <label
        :for="item.value"
        :disabled="disabled"
        class="m-0 ml-3 block cursor-pointer text-sm"
        :class="{ 'cursor-not-allowed': disabled }"
        >{{ item.label }}</label
      >
    </div>
  </div>
</template>

<script setup lang="ts">
type Option = {
  label: string;
  value: any;
};

type Props = {
  items: Option[];
  modelValue: any;
  id: string;
  disabled?: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:modelValue', value: any): void;
}>();

const selectedValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>
