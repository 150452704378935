<template>
  <ModalsContainer :open="open" :submitCallback="onValidate" @close="$emit('cancel')">
    <template #title> Ajouter un article libre </template>
    <template #subtitle> Entrez un débours HT et une description </template>

    <template #left>
      <ModalsIcon :icon="PlusIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <InputTextarea autogrow v-model="description" required focus />
        <div class="flex items-center justify-end gap-4">
          <label for="deboursHT" class="shrink-0">Débours HT</label>
          <CurrencyInput
            class="shrink basis-36"
            v-model="debours"
            id="deboursHT"
            :options="{ currency: 'EUR', valueRange: { min: -9999999, max: 999999999 } }"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PlusIcon } from '@heroicons/vue/24/outline';
import type { ArticleLibre } from '~/types/models/precommande';

const open = ref(true);

type Props = {
  callback: (article: ArticleLibre) => void;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const description = ref('');
const debours = ref(0);

const onValidate = async () => {
  if (!description) return;
  pending.value = true;
  await props.callback({
    id: '',
    description: description.value,
    debours: debours.value,
  });
  reset();
  pending.value = false;
  open.value = false;
};

const reset = () => {
  description.value = '';
  debours.value = 0;
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

watchEffect(() => {
  if (open.value) {
    window?.addEventListener('beforeunload', cancelIfOpen);
  } else {
    window?.removeEventListener('beforeunload', cancelIfOpen);
  }
  reset();
});
</script>
