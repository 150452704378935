import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

export default defineNuxtPlugin((nuxtApp) => {
  FloatingVue.options.themes.tooltip.html = true;
  FloatingVue.options.themes.tooltip.triggers = ['hover', 'touch'];
  FloatingVue.options.themes.tooltip.delay = {
    show: 200,
    hide: 0,
  };
  nuxtApp.vueApp.use(FloatingVue);
});
