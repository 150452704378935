<template>
  <ModalsContainer :open="open" :submitCallback="onValidate" @close="$emit('cancel')">
    <template #title> Fusionner deux précommandes </template>
    <template #subtitle> Sélectionnez les précommandes à fusionner </template>

    <template #left>
      <ModalsIcon :icon="MergeIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <FormInputContainer label="Précommande à fusionner">
          <SelectBox
            v-if="precommandes.length > 0"
            :items="precommandes"
            v-model="selectedPrecommande"
            :label-props="['lot.code', 'nom']"
            required />
          <div v-else class="text-skin-muted">Aucune précommande éligible</div>
        </FormInputContainer>
        <div class="flex gap-4" v-if="precommandes.length > 0">
          <ExclamationTriangleIcon class="h-7 w-7 text-skin-heading" />
          <p class="text-sm text-skin-muted">
            La précommande sélectionnée sera supprimée et ses lignes seront ajoutées à la nouvelle
            précommande.
          </p>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending || !selectedPrecommande" :loading="pending"
          >Fusionner</Button
        >
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import MergeIcon from '~/assets/svg/mergeIcon.svg';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import type { Precommande } from '~/types/models/precommande';

const open = ref(true);

type Props = {
  callback: (precmd: Precommande) => void;
  precommandes: Precommande[];
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const selectedPrecommande = ref<Precommande | null>(null);

const onValidate = async () => {
  if (!selectedPrecommande.value) return;
  pending.value = true;
  await props.callback(selectedPrecommande.value);
  pending.value = false;
  open.value = false;
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
