<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="z-overlay relative" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="z-modal fixed inset-0 overflow-y-auto bg-black bg-opacity-60">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="bg-skin-fill-2 relative overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="sm:flex sm:items-start">
                <div
                  class="bg-skin-button-danger mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-opacity-20 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <ExclamationTriangleIcon class="text-skin-danger h-6 w-6" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-skin-heading text-lg font-medium leading-6">{{
                    title
                  }}</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm" v-html="message"></p>
                  </div>
                </div>
              </div>
              <div class="mt-5 gap-2 sm:mt-4 sm:flex sm:flex-row-reverse">
                <template v-if="callback">
                  <Button @click="onValidate" :disabled="pending" :loading="pending"
                    >Continuer</Button
                  >
                  <Button variant="muted" @click="open = false">Annuler</Button>
                </template>
                <template v-else>
                  <Button variant="primary" @click="open = false">Ok</Button>
                </template>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';

const open = ref(true);
const pending = ref(false);

type Props = {
  title: string;
  message?: string;
  callback?: () => Promise<void>;
};

const props = defineProps<Props>();

const onValidate = async () => {
  if (props.callback) {
    pending.value = true;
    await props.callback();
  }
  pending.value = false;
  open.value = false;
};
</script>
