<template>
  <ModalsContainer :open="open" @close="open = false" :submitCallback="onValidate">
    <template #title> Nouvelle version </template>
    <template #subtitle> Créer une nouvelle version du modèle {{ modele.libelle }} </template>

    <template #left>
      <ModalsIcon :icon="HomeIcon" />
    </template>

    <template #content>
      <FormInputContainer label="Entrez un nom pour cette version (ex: 100+G)">
        <InputText
          v-model="libelle"
          @update:model-value="checkLibelle"
          :error-message="isUnique ? '' : 'Ce nom est déja utilisé'"
        />
      </FormInputContainer>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button variant="muted" @click="open = false" :disabled="pending">Annuler</Button>
        <Button @click="onValidate">Créer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { HomeIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';

const open = ref<boolean>(true);

type Props = {
  modele: any;
  callback: (libelle: string, modele: any) => void;
  uniqueCallback: (slug: string, modeleId: number) => boolean;
};

const props = defineProps<Props>();
const pending = ref(false);

const libelle = ref('');

const isUnique = ref(true);
const checkLibelle = useDebounceFn(async () => {
  if (libelle.value.length > 0)
    isUnique.value = await props.uniqueCallback(useSlug(libelle.value), props.modele.id);
  else isUnique.value = true;
}, 500);

const onValidate = async () => {
  if (!isUnique.value) return;
  if (libelle.value.length === 0) return;
  pending.value = true;
  await props.callback(libelle.value, props.modele);
  open.value = false;
};
</script>
