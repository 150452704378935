<template>
  <InputErrorWrapper :error-message="errorMessage">
    <div :class="[$attrs.class, currencyClasses]" :currency="currencyText" class="min-w-[40px]">
      <input
        :id="id"
        ref="input"
        v-focus
        :value="inputValue"
        :type="inputType"
        :data-type="type"
        :name="id"
        :autocomplete="autocomplete"
        class="input w-full disabled:text-skin-muted"
        :class="[
          { small: small, 'no-spin': noSpinner, 'pr-8': type === 'currency' },
          inputClasses,
          justifyClass,
        ]"
        :required="required"
        :pattern="thePattern"
        :step="step || 1"
        :disabled="disabled"
        :min="minValue"
        :max="maxValue"
        :placeholder="placeholder"
        @blur="onBlur"
        @focus="$emit('onFocus')"
        @input="onInput"
        @keydown="
          (e) => {
            if (disableKeyboard) e.preventDefault();
          }
        " />
    </div>
  </InputErrorWrapper>
</template>

<script setup lang="ts">
defineOptions({
  inheritAttrs: true,
});

type Props = {
  modelValue: string | number | null | undefined;
  id?: string;
  errorMessage?: string;
  autocomplete?: string;
  required?: boolean;
  type?: string;
  pattern?: string;
  step?: number | string;
  small?: boolean;
  noSpinner?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  placeholder?: string;
  focus?: boolean;
  currencyText?: string;
  inputClasses?: string;
  disableKeyboard?: boolean;
  justify?: 'start' | 'end';
};

const props = withDefaults(defineProps<Props>(), {
  type: 'text',
});

const vFocus = {
  mounted: (el: HTMLElement) => props.focus && el.focus(),
};

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number | null | undefined): void;
  (e: 'blur'): void;
  (e: 'onFocus'): void;
}>();

const isNumericType = computed(() => props.type === 'number' || props.type === 'currency');

const sanitizeNumberInput = (input: string) => {
  // Supprimer les espaces avant et après la chaîne et remplacer les virgules par des points. Si la chaîne est vide, retournez 0
  let cleanedInput = input?.trim()?.replace(',', '.') || '0';

  cleanedInput = cleanedInput.replaceAll(/[^0-9.-]/g, '');

  // if the input contains multiple dots, remove all but the first one
  if (cleanedInput.split('.').length > 2) {
    cleanedInput = cleanedInput.replace(/\.([^.]*)$/, '$1');
  }

  // if the input contains a dash that is not at the beginning, remove all such dashes
  cleanedInput = cleanedInput.replace(/(?!^)-/g, '');

  return cleanedInput;
};

const currencyClasses = ref(
  props.type === 'currency'
    ? `relative block after:absolute after:w-6 after:bg-skin-fill-5 after:bg-opacity-20 after:right-0 after:top-0 after:bottom-0 after:z-[10] after:rounded-r-md after:content-[attr(currency)] after:flex after:items-center after:justify-center after:text-skin-heading after:text-sm after:font-light`
    : '',
);

const isAWellFormattedNumber = (value: string) => /^-?\d+(\.\d*)?$/.test(value);

const onInput = (e: Event) => {
  const target = e.target as HTMLInputElement;
  const value = target.value;

  const caretPosition = target.selectionStart;
  const oldLength = value.length;

  if (isNumericType.value) {
    if (value === '') {
      return;
    }
    if (value === '-') {
      target.value = value;
      return;
    }
    if (value === '.') {
      target.value = '0.';
      return;
    }
    if (value === '0-') {
      target.value = '-';
      return;
    }
    const sanitizedValue = sanitizeNumberInput(value);

    if (isAWellFormattedNumber(sanitizedValue)) {
      inputValue.value = sanitizedValue;
      target.value = sanitizedValue;
    } else {
      inputValue.value = 0;
      target.value = '0';
    }
  } else {
    inputValue.value = target.value;
  }

  const newLength = target.value.length;
  const newCaretPosition = (caretPosition || 0) + (newLength - oldLength);

  if (Object.prototype.hasOwnProperty.call(target, 'setSelectionRange')) {
    target.setSelectionRange(newCaretPosition, newCaretPosition);
  }
};

const onBlur = (e: Event) => {
  if (isNumericType.value) {
    const target = e.target as HTMLInputElement;
    const floatValue = parseFloat(target.value);
    if (isNaN(floatValue)) {
      if (inputValue.value !== 0) inputValue.value = 0;
      target.value = '0';
    } else {
      if (inputValue.value !== floatValue) inputValue.value = floatValue;
    }

    if (target.value.endsWith('.')) {
      target.value = target.value.slice(0, -1);
    }
  }
  emit('blur');
};

const inputValue = computed({
  get() {
    if (props.type === 'tel') return usePhoneFormat(props.modelValue as string);
    if (props.type === 'date') return useDateFormat(props.modelValue as string, 'yyyy-MM-dd');
    return props.modelValue;
  },
  set(value: string | number | null | undefined) {
    if (isNumericType.value) {
      if (value == null || value === undefined || Number.isNaN(value)) {
        emit('update:modelValue', 0);
        return;
      }

      // if value ended by '.' do not emit
      if (value.toString().endsWith('.')) return;
      emit('update:modelValue', parseFloat(value as string));
    } else {
      let toEmit = value;
      if (typeof toEmit === 'string') toEmit = useSanitizeUserTextInput(toEmit);
      emit('update:modelValue', toEmit || null);
    }
  },
});

const patterns = [
  {
    type: '_tel',
    pattern:
      /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
  },
];

const thePattern = computed(() => {
  const pattern = patterns.find((p) => p.type === props.type);
  return props.pattern || pattern?.pattern.source || undefined;
});

const minValue = computed(() => {
  if (props.type === 'date') return '1900-01-01';
  return props.min;
});

const maxValue = computed(() => {
  if (props.type === 'date') return '2200-01-01';
  return props.max;
});

const inputType = computed(() => {
  if (isNumericType.value) return 'text';
  return props.type;
});

const input = ref<HTMLInputElement | null>(null);

// eslint-disable-next-line vue/no-dupe-keys
const focus = () => {
  if (input.value) {
    input.value.setSelectionRange(input.value.value.length, input.value.value.length, 'forward');
    input.value.focus();
  }
};

const justifyClass = computed(() => {
  return props.justify === 'end' ? '!text-right' : '!text-left';
});

defineExpose({ focus });
</script>
