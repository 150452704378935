<template>
  <ModalsContainer
    :open="open"
    :submitCallback="onValidate"
    @close="$emit('cancel')"
    :initial-focus="libelleInput"
  >
    <template #title> Ajouter une condition de règlement </template>
    <template #subtitle> Saisissez les informations de la condition de règlement </template>

    <template #left>
      <ModalsIcon :icon="PencilIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4" ref="nomInput" v-if="editedItem">
        <FormInputContainer label="Libellé">
          <InputText
            ref="libelleInput"
            type="text"
            v-model="editedItem.libelle"
            :required="true"
            id="code-echeance"
          />
        </FormInputContainer>
        <FormInputContainer label="Délai de paiement">
          <SelectBox
            v-model="editedItem.delai"
            :items="lesDelaisPossibles"
            :id-field="'label'"
            :label-props="['label']"
          />
        </FormInputContainer>
        <FormInputContainer
          label="Pénalité de retard"
          :informations="'Les pénalités minimales imposées par la loi sont de 3 fois le taux d\'interêt légal en vigueur'"
        >
          <InputTextarea v-model="editedItem.penalite" />
        </FormInputContainer>
        <FormInputContainer label="Escompe pour paiement anticipé">
          <InputTextarea type="text" v-model="editedItem.escompte" />
        </FormInputContainer>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PencilIcon } from '@heroicons/vue/24/outline';
import type { ConditionDeReglement, DelaiDePaiement, TypeFactu } from '~/types/models/workspace';

const open = ref(true);

type Props = {
  callback: (e: ConditionDeReglement) => void;
  condition: ConditionDeReglement;
};

const libelleInput = ref<HTMLInputElement>();

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const lesDelaisPossibles = [
  {
    label: 'Comptant',
    delaiJours: 0,
    finDeMois: false,
  },
  {
    label: 'A Réception',
    delaiJours: 0,
    finDeMois: false,
  },
  {
    label: '15 jours',
    delaiJours: 15,
    finDeMois: false,
  },
  {
    label: '30 jours',
    delaiJours: 30,
    finDeMois: false,
  },
  {
    label: '45 jours',
    delaiJours: 45,
    finDeMois: false,
  },
  {
    label: '60 jours',
    delaiJours: 60,
    finDeMois: false,
  },
  {
    label: '30 jours fin de mois',
    delaiJours: 20,
    finDeMois: true,
  },
  {
    label: '45 jours fin de mois',
    delaiJours: 45,
    finDeMois: true,
  },
];

const editedItem = ref<ConditionDeReglement>({
  id: crypto.randomUUID(),
  libelle: '',
  delai: {
    label: lesDelaisPossibles[0].label,
    delaiJours: 0,
    finDeMois: false,
  },
  penalite: "3 fois le taux d'intérêt légal en vigueur",
  escompte: 'néant',
});

const onValidate = async () => {
  pending.value = true;
  if (!editedItem.value) return;
  if (!editedItem.value.id) {
    editedItem.value.id = crypto.randomUUID();
  }
  await props.callback(editedItem.value);
  pending.value = false;
  open.value = false;
  emit('cancel');
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

watch(
  () => props.condition,
  value => {
    if (value) {
      editedItem.value = JSON.parse(JSON.stringify(value));
    }
  },
  { immediate: true },
);

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
