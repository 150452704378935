import { useAppStore } from '~/stores/app';

export default defineNuxtPlugin((nuxtApp) => {
  const io = nuxtApp.$nuxtSocket({
    path: '/io/',
    transports: ['websocket'],
    reconnection: true,
    persist: true,
    teardown: false,
  });

  const appStore = useAppStore();

  io.on('version', (data) => {
    appStore.setVersionFromServer(data);
  });

  io.on('connect', () => {
    appStore.setSocketConnexionStatus(true);
  });

  io.on('disconnect', () => {
    appStore.setSocketConnexionStatus(false);
  });

  return {
    provide: {
      io: io,
    },
  };
});
