<template>
  <ModalsContainer :open="open" :submitCallback="onValidate">
    <template #title> Informations dépot PC </template>
    <template #subtitle>
      Saisissez la date de dépot du permis de construire et le délai d'instruction
    </template>

    <template #left>
      <ModalsIcon :icon="PencilIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <FormInputContainer label="Date de dépot">
          <InputText type="date" v-model="theDate" id="date-pres" required />
        </FormInputContainer>
        <FormInputContainer label="Délai d'instruction en mois">
          <InputText type="number" v-model="delai" id="delai" required :step="1" :min="1" />
        </FormInputContainer>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PencilIcon } from '@heroicons/vue/24/outline';

const open = ref(true);

type Props = {
  callback: (date: string, delai: number) => void;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const theDate = ref('');
const delai = ref(2);

const onValidate = async () => {
  if (!theDate.value || !delai.value) return;
  pending.value = true;
  await props.callback(theDate.value, delai.value);
  pending.value = false;
  open.value = false;
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
