// prettier-ignore
export class DocumentType {

  
  static readonly ACTE_AUTHENTIQUE = new DocumentType('acte_authentique', 'Acte authentique');
  static readonly AFFICHAGE_PC = new DocumentType('affichage_pc', 'Affichage PC');
  static readonly AFFICHAGE_PC_DP = new DocumentType('affichage_pc_dp', 'Affichage PC/DP');
  static readonly ARRETE_PC = new DocumentType('arrete_pc', 'Arrêté PC');
  static readonly ARRETE_PC_DP = new DocumentType('arrete_pc_dp', 'Arrêté PC/DP');
  static readonly ATTESTATION_DOMMAGE_OUVRAGE = new DocumentType('attestation_domage_ouvrage', 'Attestation dommage ouvrage');
  static readonly ATTESTATION_GARANTIE_DE_LIVRAISON = new DocumentType('attestation_garantie_de_livraison', 'Att. garantie de livraison');
  static readonly AUTRE = new DocumentType('autre', 'Autre');
  static readonly AVENANT_SIGNE = new DocumentType('avenant_signe', 'Avenant signé');
  static readonly PROPOSITION_AVENANT = new DocumentType('proposition_avenant', 'Proposition d\'avenant');
  static readonly BON_DE_CHOIX = new DocumentType('bon_de_choix', 'Bon de choix');
  static readonly BDC = new DocumentType('bdc', 'Bon de commande envoyé');
  static readonly BDC_SIGNE = new DocumentType('bdc_signe', 'Bon de commande signé');
  static readonly CADASTRE = new DocumentType('cadastre', 'Cadastre');
  static readonly CARNET_CONCEPTION_INTERIEURE = new DocumentType('carnet_conception_interieure', 'Car. conception intérieure');
  static readonly CARNET_CONCEPTION_INTERIEURE_SIGNE = new DocumentType('carnet_conception_interieure_signe', 'Car. conception intérieure signé');
  static readonly CCMI = new DocumentType('ccmi', 'CCMI');
  static readonly CCT = new DocumentType('cct', 'Cahier de Conception Technique');
  static readonly CERFA = new DocumentType('cerfa', 'Cerfa');
  static readonly CERFA_SIGNE = new DocumentType('cerfa_signe', 'Cerfa signé');
  static readonly CONTRAT_TRAVAUX = new DocumentType('contrat_travaux', 'Contrat de travaux');
  static readonly CR_VT = new DocumentType('cr_vt', 'CR VT');
  static readonly DEMANDE_PIECES_COMPLEMENTAIRES = new DocumentType('demande_pieces_complementaires', 'Demande pièces complémentaires');
  static readonly DEVIS_CARRELAGE_SANITAIRE = new DocumentType('devis_carrelage_sanitaire', 'Devis carrelage sanitaire');
  static readonly DEVIS_TERRASSIER = new DocumentType('devis_terrassier', 'Devis terrassier');
  static readonly DIP = new DocumentType('dip', 'DIP');
  static readonly ETUDE_BETON = new DocumentType('etude_beton', 'Étude béton');
  static readonly ETUDE_DE_SOL_G1 = new DocumentType('etude_de_sol_G1', 'Étude de sol G1');
  static readonly ETUDE_DE_SOL_G2 = new DocumentType('etude_de_sol_G2', 'Étude de sol G2');
  static readonly ETUDE_THERMIQUE_BBIO = new DocumentType('etude_thermique_bbio', 'Étude thermique BBIO');
  static readonly ETUDE_THERMIQUE_DEFINITIVE = new DocumentType('etude_thermique_definitive', 'Étude thermique définitive');
  static readonly FICHE_CONTRAT = new DocumentType('fiche_contrat', 'Fiche contrat');
  static readonly FICHE_DE_DEMANDE_AVP = new DocumentType('fiche_de_demande_avp', 'Fiche de demande AVP');
  static readonly FICHE_DE_PRIX = new DocumentType('fiche_de_prix', 'Fiche de prix remise au client');
  static readonly FICHE_FINANCIERE = new DocumentType('fiche_financiere', 'Fiche financière');
  static readonly HUISSIER = new DocumentType('huissier', 'Huissier');
  static readonly JUSTIF_APPORT_PERSONNEL = new DocumentType('justif_apport_personnel', 'Justif. apport personnel');
  static readonly NDD_SIGNEE = new DocumentType('ndd_signee', 'ND signée');
  static readonly OFFRE_DE_PRET = new DocumentType('offre_de_pret', 'Offre de prêt');
  static readonly PERSPECTIVE = new DocumentType('perspective', 'Perspective');
  static readonly PHOTO_VT = new DocumentType('photo_vt', 'Photo VT');
  static readonly PLAN = new DocumentType('plan', 'Plan');
  static readonly PLAN_AVP = new DocumentType('plan_avp', 'Plan AVP');
  static readonly PLAN_CADASTRAL = new DocumentType('plan_cadastral', 'Plan cadastral');
  static readonly PLAN_CUISINE = new DocumentType('plan_cuisine', 'Plan cuisine');
  static readonly PLAN_DE_BORNAGE = new DocumentType('plan_de_bornage', 'Plan de bornage');
  static readonly PLAN_DE_BORNAGE_DWG = new DocumentType('plan_de_bornage_dwg', 'Plan de bornage DWG');
  static readonly PLAN_DE_CALEPINAGE_SDB_SDE = new DocumentType('plan_de_calepinage_sdb_sde', 'Plan de calepinage SDB SDE');
  static readonly PLAN_DE_DISTRIBUTION = new DocumentType('plan_de_distribution', 'Plan de distribution');
  static readonly PLAN_DE_LEXISTANT = new DocumentType('plan_de_lexistant', 'Plan de l\'existant');
  static readonly PLAN_DE_MASSE = new DocumentType('plan_de_masse', 'Plan de masse');
  static readonly PLAN_DE_SITUATION = new DocumentType('plan_de_situation', 'Plan de situation');
  static readonly PLAN_DES_ZONES_ARGILES = new DocumentType('plan_des_zones_argiles', 'Plan des zones argiles');
  static readonly PLAN_DIMPLANTATION = new DocumentType('plan_dimplantation', 'Plan d\'implantation');
  static readonly PLAN_TECHNIQUE = new DocumentType('plan_technique', 'Plan technique');
  static readonly PLANS_DE_BORNAGE_CADASTRAL_DE_SITUATION = new DocumentType('plans_de_bornage_cadastral_de_situation', 'Plans de bornage cadastral de situation');
  static readonly PLANS_EXE_NON_SIGNES = new DocumentType('plans_exe_non_signes', 'Plans exe non signés');
  static readonly PLANS_EXE_SIGNES = new DocumentType('plans_exe_signes', 'Plans exe signés');
  static readonly PLANS_PC = new DocumentType('plans_pc', 'Plans PC');
  static readonly PLANS_PC_DP = new DocumentType('plans_pc_dp', 'Plans PC/DP');
  static readonly PLANS_PC_SIGNES_CLIENTS = new DocumentType('plans_pc_signes_clients', 'Plans PC signés clients');
  static readonly PLANS_PC_DP_SIGNES_CLIENTS = new DocumentType('plans_pc_dp_signes_clients', 'Plans PC/DP signés clients');
  static readonly PLANS_PC_SIGNES_PIECES_COMPLEMENTAIRES = new DocumentType('plans_pc_signes_pieces_complementaires', 'Plans PC signés pièces complémentaires');
  static readonly PLANS_PC_DP_SIGNES_PIECES_COMPLEMENTAIRES = new DocumentType('plans_pc_dp_signes_pieces_complementaires', 'Plans PC/DP signés pièces complémentaires');
  static readonly PLU = new DocumentType('plu', 'Extrait PLU');
  static readonly POUVOIR = new DocumentType('pouvoir', 'Pouvoir');
  static readonly PROCURATION_MANDAT_DE_DEPOT_PC = new DocumentType('procuration_mandat_de_depot_pc', 'Procuration mandat de dépôt PC');
  static readonly PROCURATION_MANDAT_DE_DEPOT_PC_DP = new DocumentType('procuration_mandat_de_depot_pc_dp', 'Procuration mandat de dépôt PC/DP');
  static readonly RAR_CONTRAT = new DocumentType('rar_contrat', 'RAR contrat');
  static readonly RECEPISSE_DEPOT_PC = new DocumentType('recepisse_depot_pc', 'Récépissé dépôt PC');
  static readonly RECEPISSE_DEPOT_PC_DP = new DocumentType('recepisse_depot_pc_dp', 'Récépissé dépôt PC/DP');
  static readonly RECEPISSE_DEPOT_PIECES_COMPLEMENTAIRES = new DocumentType('recepisse_depot_pieces_complementaires', 'Récépissé dépôt pièces complémentaires');
  static readonly RECEPISSE_RAR = new DocumentType('recepisse_rar', 'Récepissé RAR');
  static readonly REFUS_PC = new DocumentType('refus_pc', 'Refus PC');
  static readonly REFUS_PC_DP = new DocumentType('refus_pc_dp', 'Refus PC/DP');
  static readonly REGLEMENT_PLU = new DocumentType('reglement_plu', 'Réglément PLU');
  static readonly RELEVE_VT = new DocumentType('releve_vt', 'Rélevé VT');
  static readonly SIMULATION = new DocumentType('simulation', 'Simulation');
  static readonly SOUS_SEING = new DocumentType('sous_seing', 'Sous seing');
  static readonly TITRE_DE_PROPRIETE = new DocumentType('titre_de_propriete', 'Titre de propriété');
  static readonly VISUEL = new DocumentType('visuel', 'Viusel');
  static readonly VTC = new DocumentType('vtc', 'VTC');
  static readonly RIB = new DocumentType('rib', 'RIB');
  static readonly KBIS = new DocumentType('kbis', 'KBIS');
  static readonly PGC = new DocumentType('pgc', 'PGC');
  static readonly ATTESTATION_VIGILANCE_URSSAF = new DocumentType('attestation_vigilance_urssaf', 'Attestation de vigilance URSSAF');
  static readonly ATTESTATION_SUR_LHONNEUR = new DocumentType('attestation_sur_lhonneur', 'Attestation sur l\'honneur');
  static readonly ASSURANCE_DECENNALE = new DocumentType('assurance_decennale', 'Assurance décennale');
  static readonly ASSURANCE_RC = new DocumentType('assurance_rc', 'Assurance RC');
  static readonly DEVIS_SSTTFOUR = new DocumentType('devis_ssttfour', 'Devis');
  static readonly DOCUMENT_TERRAIN = new DocumentType('document_terrain', 'Document terrain');
  static readonly PHOTO_TERRAIN = new DocumentType('photo_terrain', 'Photo terrain');
  static readonly CR_VTC = new DocumentType('cr_vtc', 'CR VTC');
  static readonly CR_RPO = new DocumentType('cr_rpo', 'CR RPO');
  static readonly DROC = new DocumentType('droc', 'DROC');
  static readonly ETUDE_VMC = new DocumentType('etude_vmc', 'Étude VMC');

  static readonly PHOTO = new DocumentType('photo', 'Photo');
  static readonly PLAN_FOURNISSEUR = new DocumentType('plan_fournisseur', 'Plan fournisseur');
  static readonly PLANNING_CHANTIER = new DocumentType('planning_chantier', 'Planning chantier');
  static readonly PROCES_VERBAL = new DocumentType('proces_verbal', 'Procès verbal de réception signé');
  static readonly PROCES_VERBAL_RESERVES = new DocumentType('proces_verbal_reserves', 'Procès verbal de levée des reserves signé');
  static readonly PROCES_VERBAL_AUTRE = new DocumentType('proces_verbal_autre', 'Procès verbal');
  static readonly HOMEBOARDING = new DocumentType('homeboarding', 'Homeboarding');

  static readonly RECEPTION = new DocumentType('reception', 'Réception');
  static readonly SECURITE = new DocumentType('securite', 'Sécurité');
  static readonly VT = new DocumentType('vt', 'VT');
  static readonly COURRIER_CLIENT = new DocumentType('courrier_client', 'Courrier client');


  static readonly DEMANDE_AVENANT = new DocumentType('demande_avenant', 'Demande d\'avenant');
  static readonly PLAN_ELECTRIQUE = new DocumentType('plan_electrique', 'Plan électrique');
  static readonly ETUDE_HYDROGEOLOGIQUE = new DocumentType('etude_hydrogeologique', 'Étude hydrogéologique');

  static readonly FACTURE = new DocumentType('facture', 'Facture');

  private constructor(readonly name: string, readonly label: any) {}

  toString() {
    return this.name;
  }

  static findByName(name: string): DocumentType | null {
    if (!name) {
      return null;
    }
    return (DocumentType as any)[name.toUpperCase()];
  }

  static all(): DocumentType[] {
    return Object.values(DocumentType) as DocumentType[];
  }
}
