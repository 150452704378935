<template>
  <ModalsContainer :open="open" :submitCallback="onValidate">
    <template #title> Déplacer le ligne dans une autre précommande </template>
    <template #subtitle> Séléctionnez la précommande de destination </template>

    <template #left>
      <ModalsIcon :icon="ArrowsUpDownIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-1">
        <SelectBox
          v-model="selectedPrecommande"
          :items="orderedPrecommandes"
          :label-props="['lot.code', 'nom']"
          id-field="id"
          required
          uppercase
        />
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { ArrowsUpDownIcon } from '@heroicons/vue/24/outline';
import type { Precommande } from '~/types/models/precommande';

const open = ref(true);

type Props = {
  currentPrecommande: Precommande;
  precommandes: Precommande[];
  callback: (precommande: Precommande) => void;
};

const props = defineProps<Props>();

const orderedPrecommandes = computed(() => {
  return props.precommandes.sort((a, b) => {
    if (a.lot.code !== b.lot.code) return a.lot.code.localeCompare(b.lot.code);
    return a.nom.localeCompare(b.nom);
  });
});

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const selectedPrecommande = ref(props.currentPrecommande);

const onValidate = async () => {
  if (!selectedPrecommande.value) return;
  pending.value = true;
  await props.callback(selectedPrecommande.value);
  pending.value = false;
  open.value = false;
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
