<template>
  <ModalsContainer :open="open" :submitCallback="onValidate">
    <template #title> {{ title }} </template>
    <template #subtitle> {{ subtitle }} </template>

    <template #left>
      <ModalsIcon :icon="CurrencyEuroIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <div v-if="informationsComplementaires">
          <div v-for="(item, index) in informationsComplementaires" :key="index">
            <div class="flex items-center gap-4 text-sm">
              <span class="text-skin-muted">{{ item.label }} : </span>
              <span class="font-medium">{{ item.value }}</span>
            </div>
          </div>
        </div>
        <FormInputContainer label="Montant">
          <CurrencyInput v-model="theValue" :options="{ currency: 'EUR' }" required />
        </FormInputContainer>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { CurrencyEuroIcon } from '@heroicons/vue/24/outline';

const open = ref(true);

type Props = {
  title: string;
  subtitle: string;
  currentValue: number;
  informationsComplementaires?: { label: string; value: string }[];
  callback: (value: number) => void;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const theValue = ref(props.currentValue);

const onValidate = async () => {
  pending.value = true;
  await props.callback(theValue.value);
  pending.value = false;
  open.value = false;
  emit('cancel');
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
