<template>
  <ModalsContainer :open="open" :submitCallback="onValidate" @close="$emit('cancel')">
    <template #title> Ajouter une commande </template>
    <template #subtitle> Choissisez le type de commande et le sous-lot concerné </template>

    <template #left>
      <ModalsIcon :icon="PlusIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <FormInputContainer label="Libellé">
          <InputText v-model="newCmd.libelle" required />
        </FormInputContainer>
        <FormInputContainer label="Type de commande" v-if="!type">
          <SelectBox
            :items="types"
            v-model="newCmd.type"
            :label-props="['label']"
            value-field="value"
            id-field="value"
            required
          />
        </FormInputContainer>
        <FormInputContainer label="Sous-lot">
          <SelectBox
            :items="sousLots"
            v-model="newCmd.sousLot"
            :label-props="['code', 'libelle']"
            id-field="code"
            required
          />
        </FormInputContainer>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PlusIcon } from '@heroicons/vue/24/outline';
import { CommandeStatus, PrecommandeTypes, PrecommandeTypesNames } from '~/types/Enums';
import type { Commande } from '~/types/models/commande';

const open = ref(true);

type Props = {
  callback: (cmd: Creatable<Commande>) => void;
  sousLots: any[];
  type?: PrecommandeTypes;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const types = ref(
  Object.keys(PrecommandeTypes).map((t: string) => {
    return {
      value: PrecommandeTypes[t as keyof typeof PrecommandeTypes],
      label: PrecommandeTypesNames[t as keyof typeof PrecommandeTypesNames],
    };
  }),
);

const newCmd = ref<Creatable<Commande> & { sousLot: any }>({
  statut: CommandeStatus.BROUILLON,
  libelle: '',
  projet: null,
  type: props.type || '',
  sousLot: null,
  reference: '',
  lignes: [],
  documents: [],
  commentaires: '',
});

const onValidate = async () => {
  pending.value = true;
  await props.callback(newCmd.value);
  pending.value = false;
  open.value = false;
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
