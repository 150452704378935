<template>
  <ModalsContainer :open="open" :submitCallback="onValidate" @close="$emit('cancel')">
    <template #title> Ajouter un article libre </template>
    <template #subtitle> Entrez le prix TTC, la TVA applicable et une description </template>

    <template #left>
      <ModalsIcon :icon="PlusIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <InputTextarea autogrow v-model="newArticle.description" required focus />
        <div class="flex justify-between gap-4">
          <div class="flex items-center justify-end gap-2">
            <label for="prixTTC" class="shrink-0">TVA :</label>
            <SelectBox class="w-24" v-model="newArticle.tva" :items="tvas" :valueField="'id'"  />
          </div>
          <div class="flex items-center justify-end gap-2">
            <label for="prixTTC" class="shrink-0">Prix TTC :</label>
            <CurrencyInput
              class="shrink basis-36"
              v-model="newArticle.pvTTC"
              id="prixTTC"
              :options="{ currency: 'EUR', valueRange: { min: -9999999, max: 999999999 } }"
            />
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Enregistrer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PlusIcon } from '@heroicons/vue/24/outline';
import type { ArticleLibreAvenant } from '~~/types/models/avenant';

const open = ref(true);

type Props = {
  currentArticle?: ArticleLibreAvenant;
  callback: (article: ArticleLibreAvenant) => void;
  tvas: { id: number; name: string }[];
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const tvaPrincipale = ref(props.tvas[0]);

const emptyArticle: ArticleLibreAvenant = {
  id: 0,
  description: '',
  pvTTC: 0,
  tva: tvaPrincipale.value.id,
};

const newArticle = ref(emptyArticle);

// watch currentArticle and update newArticle
watch(
  () => props.currentArticle,
  value => {
    if (value) {
      newArticle.value = JSON.parse(JSON.stringify(value));
    }
  },
  { immediate: true },
);

const onValidate = async () => {
  if (!newArticle.value.description) return;
  pending.value = true;
  await props.callback(newArticle.value);
  pending.value = false;
  open.value = false;
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  if (!!!props.currentArticle) newArticle.value = { ...emptyArticle };
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
