import revive_payload_client_4sVQNw7RlN from "/home/runner/work/briik-frontend/briik-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/briik-frontend/briik-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/briik-frontend/briik-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/briik-frontend/briik-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/briik-frontend/briik-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/briik-frontend/briik-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/runner/work/briik-frontend/briik-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/briik-frontend/briik-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/briik-frontend/briik-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/home/runner/work/briik-frontend/briik-frontend/.nuxt/floating-vue.mjs";
import plugin_WLsn00x1qh from "/home/runner/work/briik-frontend/briik-frontend/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import sentry_client_VdOeuw25Mb from "/home/runner/work/briik-frontend/briik-frontend/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/runner/work/briik-frontend/briik-frontend/.nuxt/sentry-client-config.mjs";
import plugin_ybZ5mczHdl from "/home/runner/work/briik-frontend/briik-frontend/node_modules/nuxt-socket-io/lib/plugin.js";
import strapi_CICqBBANNd from "/home/runner/work/briik-frontend/briik-frontend/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import index_client_y3EB1rkefW from "/home/runner/work/briik-frontend/briik-frontend/plugins/modals/index.client.ts";
import casl_QTBkbB6FCs from "/home/runner/work/briik-frontend/briik-frontend/plugins/casl.ts";
import floating_vue_BiOD74u9sH from "/home/runner/work/briik-frontend/briik-frontend/plugins/floating-vue.ts";
import io_J2wwqL4RIt from "/home/runner/work/briik-frontend/briik-frontend/plugins/io.js";
import loading_GxQ2Z9Goxa from "/home/runner/work/briik-frontend/briik-frontend/plugins/loading.ts";
import lodash_oeOolPNC0c from "/home/runner/work/briik-frontend/briik-frontend/plugins/lodash.js";
import strapi_client_ct4QNcuBZX from "/home/runner/work/briik-frontend/briik-frontend/plugins/strapi.client.js";
import toast_Zl2rZYKMaV from "/home/runner/work/briik-frontend/briik-frontend/plugins/toast.js";
import vcalendar_ChXOfeiz71 from "/home/runner/work/briik-frontend/briik-frontend/plugins/vcalendar.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  floating_vue_EIcJ7xiw0h,
  plugin_WLsn00x1qh,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_ybZ5mczHdl,
  strapi_CICqBBANNd,
  index_client_y3EB1rkefW,
  casl_QTBkbB6FCs,
  floating_vue_BiOD74u9sH,
  io_J2wwqL4RIt,
  loading_GxQ2Z9Goxa,
  lodash_oeOolPNC0c,
  strapi_client_ct4QNcuBZX,
  toast_Zl2rZYKMaV,
  vcalendar_ChXOfeiz71
]