export const useSanitizeUserTextInput = (text: string) => {
  if (!text) return '';
  // Expression régulière pour enlever toutes les balises HTML
  // const regex = /(<([^>]+)>)/gi;
  let clean = text?.toString() || '';
  // Remplacer toutes les balises HTML qui ne sont pas <b> ou <strong> par une chaîne vide
  // let clean = text.replace(regex, (match, p1) => {
  //   if (p1 !== '<b>' && p1 !== '</b>' && p1 !== '<strong>' && p1 !== '</strong>') {
  //     return '';
  //   } else {
  //     return match;
  //   }
  // });

  // Retire les scripts
  clean = clean.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');

  // Retire les attributs potentiellement dangereux
  clean = clean.replace(/ on\w+="[^"]*"/gi, '');

  // Trim la chaîne
  //clean = clean.trim();

  // Retourne la chaîne nettoyée
  return clean;
};
