<template>
  <Popover class="relative">
    <slot name="button" :date="selectedDate">
      <Button :icon="CalendarDaysIcon" variant="secondary" :disabled="disabled">
        {{ props.modelValue ? useDateFormat(props.modelValue) : 'jj / mm / aaaa' }}
      </Button>
    </slot>
    <template #panel="{ close }">
      <DatePicker v-model="selectedDate" ref="datePicker" @update:modelValue="close()" />
    </template>
  </Popover>
</template>

<script setup lang="ts">
import { CalendarDaysIcon } from '@heroicons/vue/24/outline';
import { DateTime } from 'luxon';
import { DatePicker } from 'v-calendar';

type Props = {
  modelValue?: string | null;
  disabled?: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits(['update:modelValue']);

const datePicker = ref();

const selectedDate = computed({
  get: () => {
    return props.modelValue ? DateTime.fromISO(props.modelValue).toJSDate() : '';
  },
  set: value => {
    emit('update:modelValue', value ? DateTime.fromJSDate(value).toISODate() : null);
  },
});
</script>

<style>
.vc-title > span {
  @apply !text-skin-heading !text-lg !font-medium;
}
</style>
