<template>
  <ModalsContainer :open="open" :submitCallback="onValidate" @close="$emit('cancel')">
    <template #title> Création d'un avenant </template>
    <template #subtitle> Définissez le type d'avenant </template>

    <template #left>
      <ModalsIcon :icon="PlusIcon" />
    </template>

    <template #content>
      <div class="flex flex-col gap-4">
        <FormInputContainer
          label="Avenant de conception intérieur&nbsp;?"
          direction="row"
          :nowrap="true"
          v-if="!!!isPhaseTravaux"
        >
          <InputToggle v-model="isConception" />
        </FormInputContainer>
        <FormInputContainer label="Avenant Travaux ? " direction="row" :nowrap="true">
          <InputToggle v-model="isTravaux" />
        </FormInputContainer>
        <FormInputContainer
          label="Modification du délai travaux : "
          direction="row"
          v-if="isTravaux"
        >
          <div class="flex items-center gap-2 text-sm">
            <div>+</div>
            <InputText type="number" v-model="dureeTravauxAdditionnelle" small class="w-14" />
            <div>mois</div>
          </div>
        </FormInputContainer>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end gap-2">
        <Button type="reset" variant="muted" @click="cancel" :disabled="pending">Annuler</Button>
        <Button type="submit" :disabled="pending" :loading="pending">Créer</Button>
      </div>
    </template>
  </ModalsContainer>
</template>

<script setup lang="ts">
import { PlusIcon } from '@heroicons/vue/24/outline';
import type { AvenantMetas } from '~/types/models/avenant';

const open = ref(true);

type Props = {
  isPhaseTravaux: boolean;
  callback: (infos: AvenantMetas) => void;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'cancel'): void;
}>();

const pending = ref(false);

const isConception = ref(false);
const isTravaux = ref(props.isPhaseTravaux === true ? true : false);
const dureeTravauxAdditionnelle = ref(0);

const onValidate = async () => {
  pending.value = true;

  await props.callback({
    isConception: isConception.value,
    isTravaux: isTravaux.value,
    dureeTravauxAdditionnelle: isTravaux.value ? dureeTravauxAdditionnelle.value : undefined,
  });
  pending.value = false;
  open.value = false;
};

const cancel = async () => {
  open.value = false;
  emit('cancel');
};

const cancelIfOpen = () => {
  if (open.value) cancel();
};

onMounted(() => {
  window?.addEventListener('beforeunload', cancelIfOpen);
});

onUnmounted(() => {
  window?.removeEventListener('beforeunload', cancelIfOpen);
});
</script>
